import { createReducer } from '@reduxjs/toolkit';
import { closeAlert, showError, showSuccessful } from './notify.actions';
import { INotifyState } from './notify.interface';

const initState: INotifyState = {
  notifications: [],
};

export const notifyReducer = createReducer(initState, (builder) =>
  builder
    .addCase(showError, (state, action) => {
      state.notifications = [
        ...state.notifications,
        {
          key: new Date().getTime() + Math.floor(Math.random() * 1000),
          message: action.payload.message,
          options: {
            ...(action.payload.options as object),
            variant: 'error',
          },
        },
      ];
    })
    .addCase(showSuccessful, (state, action) => {
      state.notifications = [
        ...state.notifications,
        {
          key: new Date().getTime() + Math.floor(Math.random() * 1000),
          message: action.payload.message,
          options: {
            ...(action.payload.options as object),
            variant: 'success',
          },
        },
      ];
    })
    .addCase(closeAlert, (state, action) => {
      state.notifications = [...state.notifications.filter((notify) => notify.key !== action.payload.key)];
    }),
);
