import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import store from 'store';
import { showError, showSuccessful } from 'store/notify/notify.actions';
import { setPaginationSearchSpinner } from 'store/table/table.actions';
import { sessionExpired } from 'store/user/user.actions';
import { api } from 'utils/axiosInstance';
import {
  createAdministrator,
  createAdministratorError,
  createAdministratorSuccess,
  editAdministrator,
  editAdministratorSuccess,
  getAdministratorById,
  getAdministratorByIdError,
  getAdministratorByIdSuccess,
  getAdministratorsPageable,
  getAdministratorsPageableError,
  getAdministratorsPageableSuccess,
} from './administrator.actions';

export const createAdministratorEpic: Epic = (action$, _, { browserHistory }) =>
  action$.pipe(
    ofType(createAdministrator.type),
    switchMap(({ payload }) =>
      from(api.createAdministrator(payload)).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'administrator.success.create' }));
          browserHistory.push('/administrators');
          return of(createAdministratorSuccess());
        }),
        catchError(({ response: { status } }) => {
          store.dispatch(showError({ message: 'administrator.errors.create' }));
          if (status === 409) {
            return of(createAdministratorError('administrator.errors.duplicate'));
          } else if (status === 403) {
            return of(sessionExpired(true));
          }
          return of(createAdministratorError(undefined));
        }),
      ),
    ),
  );

export const getAdministratorByIdEpic: Epic = (actions$, _, { browserHistory }) =>
  actions$.pipe(
    ofType(getAdministratorById.type),
    switchMap(({ payload }) =>
      from(api.getAdministrator(payload)).pipe(
        switchMap(({ data }) => of(getAdministratorByIdSuccess(data))),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }
          browserHistory.push('/administrators');
          store.dispatch(showError({ message: 'administrator.errors.noId' }));
          return of(getAdministratorByIdError());
        }),
      ),
    ),
  );

export const getAdministratorsPageableEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getAdministratorsPageable.type),
    switchMap(({ payload }) =>
      from(api.getAllAdministrators(payload)).pipe(
        switchMap(({ data }) => {
          store.dispatch(setPaginationSearchSpinner(false));
          return of(getAdministratorsPageableSuccess(data));
        }),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }
          store.dispatch(setPaginationSearchSpinner(false));
          store.dispatch(showError({ message: 'administrator.errors.get' }));
          return of(getAdministratorsPageableError());
        }),
      ),
    ),
  );

export const editAdministratorEpic: Epic = (actions$, _, { browserHistory }) =>
  actions$.pipe(
    ofType(editAdministrator.type),
    switchMap(({ payload }) =>
      from(api.editAdministrator(payload.administratorId, payload.updateAdministrator)).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'administrator.success.edit' }));
          browserHistory.push('/administrators');
          return of(editAdministratorSuccess());
        }),
        catchError(({ response: { status } }) => {
          store.dispatch(showError({ message: 'administrator.errors.edit' }));
          if (status === 409) {
            return of(createAdministratorError('administrator.errors.duplicate'));
          } else if (status === 403) {
            return of(sessionExpired(true));
          }
          return of(createAdministratorError(undefined));
        }),
      ),
    ),
  );

export const administratorEpics = combineEpics(
  createAdministratorEpic,
  getAdministratorsPageableEpic,
  getAdministratorByIdEpic,
  editAdministratorEpic,
);
