import { IconButtonTypeMap, SelectProps, TablePagination } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import { OverrideProps } from '@material-ui/core/OverridableComponent';
import React, { ChangeEvent, MouseEvent, PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import PaginationTransLabel from './PaginationTransLabel';

export interface IPaginationProps {
  onChangePage: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  page: number;
  rowsPerPage: number;
  count?: number;
  nativeSelect?: boolean;
}

class Pagination extends PureComponent<IPaginationProps & WithTranslation> {
  getPaginationLabelDisplayedRows({ to, from, count }: { to: number; from: number; count: number }): JSX.Element {
    return <PaginationTransLabel to={to} from={from} count={count} />;
  }
  render(): JSX.Element {
    const { count, t, onChangePage, onChangeRowsPerPage, page, rowsPerPage } = this.props;
    return (
      <TablePagination
        data-testid="pagination"
        SelectProps={
          {
            'data-testid': 'select',
            native: this.props.nativeSelect,
          } as Partial<SelectProps>
        }
        labelDisplayedRows={this.getPaginationLabelDisplayedRows}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        labelRowsPerPage={t('table.labelRowsPerPage')}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        count={count !== undefined ? count : -1}
        nextIconButtonProps={
          { 'data-testid': 'nextPage' } as Partial<OverrideProps<IconButtonTypeMap<{}, 'button'>, 'button'>> | undefined
        }
        backIconButtonProps={
          { 'data-testid': 'previousPage' } as
            | Partial<OverrideProps<IconButtonTypeMap<{}, 'button'>, 'button'>>
            | undefined
        }
        nextIconButtonText={t('table.nextPage')}
        backIconButtonText={t('table.previousPage')}
      />
    );
  }
}

export default withTranslation()(Pagination);
