import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { ITablePaginationOptions } from './table.interface';

const paginationOptionsSelector = (state: AppState): ITablePaginationOptions => state.table.paginationOptions;

const searchSpinnerSelector = (state: AppState): boolean => state.table.searchSpinner;

export const getPaginationOptionsSelector = createSelector(
  paginationOptionsSelector,
  (paginationOptions) => paginationOptions,
);

export const getPaginationSearchSpinnerSelector = createSelector(
  searchSpinnerSelector,
  (searchSpinner) => searchSpinner,
);
