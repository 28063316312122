/** @jsx jsx */
import { jsx } from '@emotion/core';
import { CircularProgress, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import IconButtonLabel from 'components/IconButtonLabel/IconButtonLabel';
import { ChangeEvent, Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { setPaginationSearch, setPaginationSearchSpinner } from 'store/table/table.actions';
import { getPaginationSearchSpinnerSelector } from 'store/table/table.selectors';
import { tableToolbarStyles } from './TableToolbar.style';

interface IProps {
  buttonLabelKey: string;
  onAdd: () => void;
}

interface IStateProps {
  searchSpinner: boolean;
}

interface IDispatchProps {
  setSearch: (payload: string) => void;
  setPaginationSearchSpinner: (payload: boolean) => void;
}
export class TableToolbar extends Component<IProps & IStateProps & IDispatchProps> {
  state = {
    searchValue: '',
  };

  debounceFn: Function | undefined;

  throttleFn: (() => void) | undefined;
  throttle(callbackFunc: Function, limit: number): () => void {
    let wait = false;
    return (): void => {
      if (!wait) {
        callbackFunc();
        wait = true;
        setTimeout(function () {
          wait = false;
        }, limit);
      }
    };
  }

  debounce(callbackFunc: Function, limit: number): () => void {
    let timeout: NodeJS.Timeout | null;
    return (): void => {
      const later = (): void => {
        timeout = null;
        callbackFunc();
      };
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(later, limit);
    };
  }

  onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    event.persist();
    if (!this.throttleFn) {
      this.throttleFn = this.throttle(() => {
        this.props.setPaginationSearchSpinner(true);
      }, 500);
      this.throttleFn();
    } else {
      this.throttleFn();
    }

    if (!this.debounceFn) {
      this.debounceFn = this.debounce(() => {
        return this.props.setSearch(event.target.value);
      }, 500);
      this.debounceFn();
    } else {
      this.debounceFn();
    }
  };

  render(): JSX.Element {
    const { buttonLabelKey, onAdd } = this.props;
    return (
      <div>
        <div css={tableToolbarStyles.root}>
          <IconButtonLabel buttonLabelKey={buttonLabelKey} onAdd={onAdd} testId="button" />
          <TextField
            margin="dense"
            variant="outlined"
            css={tableToolbarStyles.input}
            inputProps={{ 'data-testid': 'input' }}
            InputProps={{
              endAdornment: this.props.searchSpinner ? (
                <CircularProgress css={tableToolbarStyles.endAdornmentSpinner} />
              ) : (
                <SearchIcon css={tableToolbarStyles.endAdornmentIcon} />
              ),
            }}
            onChange={this.onInputChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  searchSpinner: getPaginationSearchSpinnerSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setSearch: (payload: string): void => dispatch(setPaginationSearch(payload)),
  setPaginationSearchSpinner: (payload: boolean): void => dispatch(setPaginationSearchSpinner(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableToolbar);
