/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { Fragment, PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IProps {
  text: string;
  width?: number;
}

class FormTitle extends PureComponent<IProps & WithTranslation> {
  StyledTypography = styled(Typography)`
    width: ${this.props.width ? this.props.width : '700'}px;
    font-size: 20px !important;
    margin: 16px !important;
  `;

  render(): JSX.Element {
    const { t, text } = this.props;
    const { StyledTypography } = this;
    return (
      <Fragment>
        <StyledTypography variant="h4" color="secondary" align="left" data-testid="formTitle">
          {t(text)}
        </StyledTypography>
      </Fragment>
    );
  }
}

export default withTranslation()(FormTitle);
