// @flow
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ChangePasswordForm } from '../../components/ChangePasswordForm/ChangePasswordForm';
import { AppState } from '../../store';
import { verifyPassToken } from '../../store/user/user.actions';

const ChangePasswordContainer = (): JSX.Element => {
  const location = useLocation();
  const parameters = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const token = parameters.get('token');
  useEffect(() => {
    if (token) {
      dispatch(verifyPassToken(token));
    }
  }, []);
  const selector = (state: AppState): boolean => state.user.verifyPassFinished;
  const finished = useSelector(selector);
  return <React.Fragment>{finished && <ChangePasswordForm token={token} />}</React.Fragment>;
};

export default ChangePasswordContainer;
