import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const accordionStyles = {
  root: css`
    margin: 0 0 20px 0 !important;
    box-sizing: border-box;
    width: 400px;
  `,
  summary: css`
    background: #dde0e6 !important;
    min-height: fit-content !important;

    .MuiAccordionSummary-content {
      margin: 0;
      align-items: center;
      span {
        padding-left: 8px;
      }
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }

    .Mui-expanded {
      margin: 0 !important;
    }

    .MuiIconButton-root {
      padding: 8px;
    }

    .MuiSvgIcon-root {
      padding-right: 0;
      width: auto;
    }
    .MuiSvgIcon-root.connectedIcon {
      transform-origin: center !important;
      transform: rotate(-45deg) !important;
    }

    .MuiAccordion-root {
      margin-bottom: 24px !important;
    }
  `,
  details: css`
    flex-direction: column;
  `,
  accordionError: css`
    border: 2px solid red;
    border-radius: 4px !important;
  `,
  accordionErrorText: css`
    color: red;
    text-align: start;
    max-width: 400px;
    margin: 12px 0;
  `,
  accordionLabel: css`
    font-weight: bold;
    color: #333;
    text-align: start;
    margin-top: 12px;
  `,
  spinner: css`
    width: 20px !important;
    height: auto !important;
    margin-right: 8px;
  `,
};

export const AccordionDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-top: 20px;
`;
