/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import CustomAutocomplete from 'components/Form/Input/CustomAutocomplete';
import { IClientForm } from 'containers/AddClientContainer/AddClientContainer';
import { FormApi } from 'final-form';
import { Component } from 'react';
import { Field } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IAutocompleteField, ILanguageMappingField } from 'types/shared';
import { requiredAutocomplete } from 'utils/customValidators';
import {
  CustomLanguageMappingButton,
  CustomLanguageMappingContainer,
  CustomLanguageMappingFieldContainer,
  CustomLanguageMappingLegend,
  CustomLanguageMappingRemoveButton,
} from './LanguageMappingContainer.styled';

interface IState {
  filteredLanguages: IAutocompleteField[][];
}

interface IProps {
  form: FormApi<IClientForm>;
  hubspotLanguages: Array<IAutocompleteField>;
  xtmLanguages: Array<IAutocompleteField>;
  initialValue?: Array<ILanguageMappingField>;
}

type PropType = IProps & WithTranslation;

export class LanguageMappingContainer extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);

    this.state = {
      filteredLanguages: [],
    };
  }

  componentDidUpdate(): void {
    const { form, hubspotLanguages } = this.props;
    const { filteredLanguages } = this.state;

    const languageMappingFields = form.getFieldState('languageMappings')?.value;

    if (languageMappingFields?.length && hubspotLanguages && !filteredLanguages?.length) {
      this.setFilteredLanguages();
    }
  }

  setFilteredLanguages(): void {
    const { form, hubspotLanguages } = this.props;

    setTimeout(() => {
      const languageMappingFields = form.getFieldState('languageMappings')?.value;

      let filteredLanguages: IAutocompleteField[][] = [];

      const chosenHubspotLanguages: string[] =
        languageMappingFields?.map(({ hubspotLanguage }) => hubspotLanguage?.value) || [];

      filteredLanguages =
        languageMappingFields?.map((_, index) =>
          hubspotLanguages.filter(
            ({ value }) =>
              !chosenHubspotLanguages.includes(value) || languageMappingFields[index]?.hubspotLanguage?.value === value,
          ),
        ) || [];

      this.setState({
        filteredLanguages,
      });
    }, 1);
  }

  getHubspotLanguageValue(index: number): string | undefined {
    const { form } = this.props;
    const languageMappingField = form.getFieldState('languageMappings')?.value;

    return languageMappingField?.[index]?.hubspotLanguage?.value;
  }

  handleAddLanguageMapping = (
    fields: FieldArrayRenderProps<ILanguageMappingField, HTMLElement>['fields'],
  ) => (): void => {
    fields.push({ xtmLanguage: { label: '', value: '' }, hubspotLanguage: { label: '', value: '' } });
    this.setFilteredLanguages();
  };

  handleRemoveLanguageMapping = (
    fields: FieldArrayRenderProps<ILanguageMappingField, HTMLElement>['fields'],
    index: number,
  ) => (): void => {
    fields.remove(index);
    this.setFilteredLanguages();
  };

  onHubspotLanguageChange = (index: number, fields: FieldArrayRenderProps<string, HTMLElement>['fields']) => (
    _: React.ChangeEvent<{}>,
    value: IAutocompleteField | Array<IAutocompleteField> | null,
  ): void => {
    if (!value) {
      fields.update(index, '');
    }

    this.setFilteredLanguages();
  };

  render(): JSX.Element {
    const { filteredLanguages } = this.state;
    const { t, hubspotLanguages, xtmLanguages, initialValue } = this.props;

    return (
      <FieldArray name="languageMappings" initialValue={initialValue}>
        {({ fields }): JSX.Element => (
          <CustomLanguageMappingContainer>
            <CustomLanguageMappingLegend>{t('client.languageMapping')}</CustomLanguageMappingLegend>
            {fields.map(
              (name, index: number): JSX.Element => (
                <Box display="flex" key={name} justifyContent="space-between" alignItems="flex-start">
                  <CustomLanguageMappingFieldContainer>
                    <Field
                      name={`${name}.hubspotLanguage`}
                      validate={requiredAutocomplete}
                      label="client.hubspotLanguage"
                      options={filteredLanguages[index] || []}
                      component={CustomAutocomplete}
                      column
                      placeholder={t('common.selectPlaceholder')}
                      onChange={this.onHubspotLanguageChange(
                        index,
                        (fields as unknown) as FieldArrayRenderProps<string, HTMLElement>['fields'],
                      )}
                    />
                  </CustomLanguageMappingFieldContainer>

                  <CustomLanguageMappingFieldContainer>
                    <Field
                      name={`${name}.xtmLanguage`}
                      validate={requiredAutocomplete}
                      label="client.xtmLanguage"
                      options={xtmLanguages}
                      component={CustomAutocomplete}
                      column
                      placeholder={t('common.selectPlaceholder')}
                      disabled={!this.getHubspotLanguageValue(index)}
                    />
                  </CustomLanguageMappingFieldContainer>

                  <CustomLanguageMappingRemoveButton onClick={this.handleRemoveLanguageMapping(fields, index)}>
                    <CloseIcon />
                  </CustomLanguageMappingRemoveButton>
                </Box>
              ),
            )}
            {hubspotLanguages?.length !== fields?.length && (
              <CustomLanguageMappingButton onClick={this.handleAddLanguageMapping(fields)}>
                <AddCircleIcon fontSize="large" />
              </CustomLanguageMappingButton>
            )}
          </CustomLanguageMappingContainer>
        )}
      </FieldArray>
    );
  }
}

export default withTranslation()(LanguageMappingContainer);
