import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import {
  createAdministrator,
  createAdministratorError,
  createAdministratorSuccess,
  getAdministratorById,
  getAdministratorByIdError,
  getAdministratorByIdSuccess,
  getAdministratorsPageable,
  getAdministratorsPageableError,
  getAdministratorsPageableSuccess,
} from 'store/administrator/administrator.actions';
import {
  createClient,
  createClientError,
  createClientSuccess,
  editClient,
  editClientSuccess,
  getClientById,
  getClientByIdError,
  getClientByIdSuccess,
  getClientsPageable,
  getClientsPageableError,
  getClientsPageableSuccess,
} from 'store/client/client.actions';
import {
  changePassword,
  changePasswordError,
  changePasswordSuccess,
  changeUserPassword,
  changeUserPasswordError,
  changeUserPasswordSuccess,
  createUser,
  createUserError,
  createUserSuccess,
  editUser,
  editUserSuccess,
  getDefaultUserLanguages,
  getDefaultUserLanguagesError,
  getDefaultUserLanguagesSuccess,
  getUserById,
  getUserByIdError,
  getUserByIdSuccess,
  loginUser,
  loginUserError,
  loginUserSuccess,
  resetPassword,
  resetPasswordError,
  resetPasswordSuccess,
  resetUserPassword,
  resetUserPasswordError,
  resetUserPasswordSuccess,
  usersPageable,
  usersPageableError,
  usersPageableSuccess,
  xtmConnect,
  xtmConnectError,
  xtmConnectSuccess,
  xtmConnectToCMS,
  xtmConnectToCMSError,
  xtmConnectToCMSSuccess,
  xtmConnectUser,
  xtmConnectUserError,
  xtmConnectUserSuccess,
  xtmGetConnectData,
  xtmGetConnectDataError,
  xtmGetConnectDataSuccess,
  xtmGetCustomers,
  xtmGetCustomersById,
  xtmGetCustomersByIdError,
  xtmGetCustomersByIdSuccess,
  xtmGetCustomersError,
  xtmGetCustomersSuccess,
  xtmGetTemplates,
  xtmGetTemplatesById,
  xtmGetTemplatesByIdError,
  xtmGetTemplatesByIdSuccess,
  xtmGetTemplatesError,
  xtmGetTemplatesSuccess,
} from 'store/user/user.actions';
import {
  setConnectDataSpinner,
  setConnectToCMSSpinner,
  setConnectToXtmSpinner,
  setCustomerSpinner,
  setDefaultUserLanguageSpinner,
  setFetchSpinner,
  setTemplateSpinner,
} from './spinner.actions';

const fetchSpinnerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(
      createUser.type,
      createUserSuccess.type,
      createUserError.type,
      usersPageable.type,
      usersPageableSuccess.type,
      usersPageableError.type,
      editUser.type,
      editUserSuccess.type,
      getUserById.type,
      getUserByIdSuccess.type,
      getUserByIdError.type,
      createClient.type,
      createClientSuccess.type,
      createClientError.type,
      getClientsPageable.type,
      getClientsPageableError.type,
      getClientsPageableSuccess.type,
      editClient.type,
      editClientSuccess.type,
      getClientById.type,
      getClientByIdError.type,
      getClientByIdSuccess.type,
      createAdministrator.type,
      createAdministratorSuccess.type,
      createAdministratorError.type,
      getAdministratorsPageable.type,
      getAdministratorsPageableSuccess.type,
      getAdministratorsPageableError.type,
      getAdministratorById.type,
      getAdministratorByIdSuccess.type,
      getAdministratorByIdError.type,
      loginUser.type,
      loginUserError.type,
      loginUserSuccess.type,
      resetPassword.type,
      resetPasswordError.type,
      resetPasswordSuccess.type,
      changePassword.type,
      changePasswordError.type,
      changePasswordSuccess.type,
      changeUserPassword.type,
      changeUserPasswordError.type,
      changeUserPasswordSuccess.type,
      resetUserPassword.type,
      resetUserPasswordError.type,
      resetUserPasswordSuccess.type,
    ),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setFetchSpinner(false));
      }
      return of(setFetchSpinner(true));
    }),
  );

const templateSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(
      xtmGetTemplates.type,
      xtmGetTemplatesSuccess.type,
      xtmGetTemplatesError.type,
      xtmGetTemplatesById.type,
      xtmGetTemplatesByIdError.type,
      xtmGetTemplatesByIdSuccess.type,
    ),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setTemplateSpinner(false));
      }
      return of(setTemplateSpinner(true));
    }),
  );

const customerSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(
      xtmGetCustomers.type,
      xtmGetCustomersSuccess.type,
      xtmGetCustomersError.type,
      xtmGetCustomersById.type,
      xtmGetCustomersByIdError.type,
      xtmGetCustomersByIdSuccess.type,
    ),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setCustomerSpinner(false));
      }
      return of(setCustomerSpinner(true));
    }),
  );

const connectDataSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmGetConnectData.type, xtmGetConnectDataSuccess.type, xtmGetConnectDataError.type),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setConnectDataSpinner(false));
      }
      return of(setConnectDataSpinner(true));
    }),
  );

const connectToXtmSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(
      xtmConnect.type,
      xtmConnectError.type,
      xtmConnectSuccess.type,
      xtmConnectUser.type,
      xtmConnectUserError.type,
      xtmConnectUserSuccess.type,
    ),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setConnectToXtmSpinner(false));
      }
      return of(setConnectToXtmSpinner(true));
    }),
  );

const connectToCMSSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmConnectToCMSError.type, xtmConnectToCMSSuccess.type, xtmConnectToCMS.type),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setConnectToCMSSpinner(false));
      }
      return of(setConnectToCMSSpinner(true));
    }),
  );

const defaultUserLanguagesSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(getDefaultUserLanguages.type, getDefaultUserLanguagesSuccess.type, getDefaultUserLanguagesError.type),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setDefaultUserLanguageSpinner(false));
      }
      return of(setDefaultUserLanguageSpinner(true));
    }),
  );

export const spinnerEpics = combineEpics(
  fetchSpinnerEpic,
  templateSpinnerEpic,
  connectDataSpinnerEpic,
  connectToXtmSpinnerEpic,
  customerSpinnerEpic,
  connectToCMSSpinnerEpic,
  defaultUserLanguagesSpinnerEpic,
);
