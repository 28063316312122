import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { AdministratorDTO, Page } from 'utils/restApplicationClient';

export const administratorErrorsSelector = (state: AppState): { [key: string]: string | undefined } =>
  state.administrator.errors;

export const administratorsPageableSelector = (state: AppState): Page<AdministratorDTO> | undefined =>
  state.administrator.administratorsPageable;

export const administratorSelector = (state: AppState): AdministratorDTO | undefined =>
  state.administrator.administrator;

export const getAdministratorErrorsSelector = createSelector(administratorErrorsSelector, (errors) => errors);

export const getAdministratorsPageableSelector = createSelector(
  administratorsPageableSelector,
  (administrators) => administrators,
);

export const getAdministratorSelector = createSelector(administratorSelector, (administrator) => administrator);
