import { Dispatch } from '@reduxjs/toolkit';
import XtmConnectAccordion from 'components/Form/Accordion/XtmConnectAccordion/XtmConnectAccordion';
import ButtonContainer from 'components/Form/ButtonContainer/ButtonContainer';
import ConnectedFields from 'components/Form/ConnectedFields/ConnectedFields';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomCheckbox from 'components/Form/Input/CustomCheckbox';
import CustomFieldRow from 'components/Form/Input/CustomFieldRow';
import { CustomInputError } from 'components/Form/Input/CustomInput.style';
import CustomSelect from 'components/Form/Input/CustomSelect';
import FormTitle from 'components/Form/Title/FormTitle';
import { Roles } from 'enums/roles';
import { UserRouteParameterEnum } from 'enums/userRouteParameter';
import { createForm, FormApi } from 'final-form';
import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AppDispatch, AppState } from 'store';
import { getAllClients } from 'store/client/client.actions';
import { getAllClientsSelector } from 'store/client/client.selectors';
import {
  clearUser,
  clearUserErrors,
  createUser,
  editUser,
  getDefaultUserLanguages,
  getUserById,
} from 'store/user/user.actions';
import { IEditUser } from 'store/user/user.interface';
import {
  getDefaultUserLanguagesSelector,
  getUserDataSelector,
  getUserErrorsSelector,
  getUserSelector,
  getXtmAuthorizationIdSelector,
} from 'store/user/user.selectors';
import { checkRoles } from 'utils/checkRoles';
import {
  composeValidators,
  email,
  fetchValidator,
  optionalValidator,
  password,
  required,
} from 'utils/customValidators';
import { AuthenticationResult, ClientDTO, HubspotLanguageDTO, UserDTO } from 'utils/restApplicationClient';
import { CreateUserDTO, UpdateUserDTO } from 'utils/restApplicationClientTypeOverrides';

interface IStateProps {
  errors: { [key: string]: string };
  clients: ClientDTO[];
  user?: UserDTO;
  authorizationId?: string;
  currentUser: AuthenticationResult;
  defaultLanguages: HubspotLanguageDTO[];
}

interface IDispatchProps {
  getAllClients: () => AppDispatch;
  createUser: (payload: CreateUserDTO) => AppDispatch;
  getUserById: (payload: string) => AppDispatch;
  editUser: (payload: IEditUser) => AppDispatch;
  clearUserErrors: () => AppDispatch;
  clearUser: () => AppDispatch;
  getDefaultUserLanguages: () => AppDispatch;
}

interface IState {
  submitValues?: UpdateUserDTO | CreateUserDTO | IForm;
}

interface IProps {
  native?: boolean;
}

interface IMatchParams {
  id?: string;
  type?: UserRouteParameterEnum;
}

export interface IForm extends Omit<CreateUserDTO, 'overrideTextLayersWithTranslations, importInterval'> {
  language: string;
}

export class AddUserContainer extends Component<
  WithTranslation & RouteComponentProps<IMatchParams> & IProps & IStateProps & IDispatchProps,
  IState
> {
  componentDidMount(): void {
    const {
      getAllClients,
      getUserById,
      defaultLanguages,
      getDefaultUserLanguages,
      match: {
        params: { id: userId },
      },
    } = this.props;
    clearUserErrors();
    clearUser();
    getAllClients();

    if (!defaultLanguages?.length) {
      getDefaultUserLanguages();
    }

    if (userId) {
      getUserById(userId);
    }
  }

  componentWillUnmount(): void {
    const { clearUser, clearUserErrors } = this.props;
    clearUserErrors();
    clearUser();
  }

  parseClientSelect(rows: ClientDTO[]): Array<{ value: string; name: string }> {
    return rows.map((client) => ({
      value: client.id,
      name: client.clientName,
    }));
  }

  onSubmit = (values: IForm): void => {
    const {
      match: {
        params: { id: userId, type },
      },
    } = this.props;
    this.props.clearUserErrors();
    this.setState({ submitValues: values });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { xtmAuthorizationId, ...editValues } = values;

    if (userId && type === UserRouteParameterEnum.edit) {
      this.props.editUser({
        userId,
        updateUser: editValues,
      });
    } else {
      this.props.createUser(values);
    }
  };

  form: FormApi<IForm> = createForm({
    onSubmit: this.onSubmit,
  });

  render(): JSX.Element {
    const {
      match: {
        params: { id: userId, type },
      },
      native,
      errors,
      user,
      authorizationId,
      currentUser,
      t,
    } = this.props;

    const shouldConnectedFieldsRender =
      user && user.xtmProperties && user.xtmProperties.xtmCustomerId && user.xtmProperties.xtmTemplateId;

    const isClientAdmin = checkRoles([Roles.ADMIN_CLIENT], currentUser.roles as Roles[]);

    return (
      <FormDiv>
        <FormTitle text={userId ? 'users.edit' : 'users.add'} />
        <Form
          onSubmit={this.onSubmit}
          form={this.form}
          subscription={{
            submitting: true,
            pristine: true,
            submitFailed: true,
            errors: true,
          }}
          render={({ handleSubmit, submitting, pristine, errors: stateErrors, submitFailed }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                validate={composeValidators([
                  required,
                  email,
                  fetchValidator(errors['email'], this.state?.submitValues?.email),
                ])}
                key={errors['email'] ? 'emailError' : 'email'}
                initialValue={type === 'duplicate' ? '' : user && user.email}
              >
                {({ input, meta }): JSX.Element => (
                  <CustomFieldRow
                    label="common.email"
                    error={meta.error}
                    touched={user ? true : meta.touched}
                    inputProps={input}
                    testId="emailInput"
                    disableAutoComplete={true}
                  />
                )}
              </Field>
              <Field
                name="password"
                validate={
                  user && type === UserRouteParameterEnum.edit
                    ? (value: string): null | string => optionalValidator(value, password)
                    : password
                }
              >
                {({ input, meta }): JSX.Element => (
                  <CustomFieldRow
                    label="common.password"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={input}
                    testId="passwordInput"
                    type="password"
                    disableAutoComplete={true}
                  />
                )}
              </Field>
              <Field name="firstName" validate={required} initialValue={user && user.userSpecification.firstName}>
                {({ input, meta }): JSX.Element => (
                  <CustomFieldRow
                    label="common.firstName"
                    error={meta.error}
                    touched={user ? true : meta.touched}
                    inputProps={input}
                    testId="firstNameInput"
                    disableAutoComplete={true}
                  />
                )}
              </Field>
              <Field
                name="lastName"
                validate={composeValidators([required])}
                initialValue={user && user.userSpecification.lastName}
              >
                {({ input, meta }): JSX.Element => (
                  <CustomFieldRow
                    label="common.lastName"
                    error={meta.error}
                    touched={user ? true : meta.touched}
                    inputProps={input}
                    testId="lastNameInput"
                    disableAutoComplete={true}
                  />
                )}
              </Field>
              <Field
                name="clientUUID"
                validate={required}
                initialValue={
                  isClientAdmin ? this.props.clients[0] && this.props.clients[0].id : user && user.client.id
                }
              >
                {({ input, meta }): JSX.Element => (
                  <CustomSelect
                    label="common.client"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={input}
                    testId="clientSelect"
                    rows={this.parseClientSelect(this.props.clients)}
                    selectProps={{ native, disabled: isClientAdmin }}
                  />
                )}
              </Field>
              {stateErrors && submitFailed && <CustomInputError>{t(stateErrors.error)}</CustomInputError>}

              <Field name="interfaceLanguage" defaultValue="en-EN" validate={required}>
                {({ input, meta }): JSX.Element => (
                  <CustomSelect
                    label="common.language"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={input}
                    testId="languageSelect"
                    rows={[{ value: 'en-EN', name: 'English (UK)' }]}
                    width="50%"
                    selectProps={{ native }}
                  />
                )}
              </Field>
              <Field name="status" type="checkbox" initialValue={userId ? user && user.status : true}>
                {({ input }): JSX.Element => (
                  <CustomCheckbox label="common.active" inputProps={{ ...input }} testId="checkboxInput" />
                )}
              </Field>
              <Field name="xtmAuthorizationId" validate={required}>
                {(inputProps): JSX.Element => <XtmConnectAccordion {...inputProps} />}
              </Field>
              {(authorizationId || shouldConnectedFieldsRender) && (
                <ConnectedFields form={this.form} authorizationId={authorizationId} user={user} />
              )}
              <ButtonContainer backTo="/users" submitting={submitting || pristine} />
            </form>
          )}
        />
      </FormDiv>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  getAllClients: (): AppDispatch => dispatch(getAllClients()),
  createUser: (payload: CreateUserDTO): AppDispatch => dispatch(createUser(payload)),
  editUser: (payload: IEditUser): AppDispatch => dispatch(editUser(payload)),
  getUserById: (payload: string): AppDispatch => dispatch(getUserById(payload)),
  clearUserErrors: (): AppDispatch => dispatch(clearUserErrors()),
  clearUser: (): AppDispatch => dispatch(clearUser()),
  getDefaultUserLanguages: (): AppDispatch => dispatch(getDefaultUserLanguages()),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  clients: getAllClientsSelector(state),
  errors: getUserErrorsSelector(state),
  user: getUserSelector(state),
  authorizationId: getXtmAuthorizationIdSelector(state),
  currentUser: getUserDataSelector(state),
  defaultLanguages: getDefaultUserLanguagesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddUserContainer));
