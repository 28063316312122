/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Tab, Tabs } from '@material-ui/core';
import { Roles } from 'enums/roles';
import { mainRoutes } from 'layouts/main/routes';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from 'store';
import { getUserDataSelector } from 'store/user/user.selectors';
import { IRoute } from 'types/route';
import { checkRoles } from 'utils/checkRoles';
import { AuthenticationResult, RefreshTokenResult } from 'utils/restApplicationClient';
import { navbarStyles } from './Navbar.style';

interface IProps extends RouteComponentProps {
  pages: IRoute[];
}

interface IStateProps {
  data: AuthenticationResult & RefreshTokenResult;
}

const routeValues = mainRoutes.filter((route) => route.navbar);

export class Navbar extends Component<IProps & WithTranslation & IStateProps> {
  pagesToRender: IRoute[];

  constructor(props: IProps & WithTranslation & IStateProps) {
    super(props);
    this.pagesToRender = this.props.pages.filter((element) => {
      return element.navbar && element.routeTextKey && checkRoles(element.roles, this.props.data?.roles as Roles[]);
    });
  }

  setTabValue = (): string | boolean => {
    const value = this.pagesToRender.find((element) => this.props.location.pathname.startsWith(element.pathname));
    if (value && routeValues.includes(value)) {
      return value.pathname;
    }
    return false;
  };

  handleTabChange = (pathname: string) => (): void => {
    this.props.history.push(pathname);
  };

  render(): JSX.Element {
    const { t } = this.props;
    return (
      <div css={navbarStyles.root}>
        <Tabs value={this.setTabValue()} textColor="secondary">
          {this.pagesToRender.map((element, index) => (
            <Tab
              key={index}
              label={t(element.routeTextKey as string)}
              value={element.pathname}
              onClick={this.handleTabChange(element.pathname)}
            />
          ))}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  data: getUserDataSelector(state),
});

export default connect(mapStateToProps)(withTranslation()(withRouter(Navbar)));
