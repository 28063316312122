import { createReducer } from '@reduxjs/toolkit';
import { OrderDirection } from 'enums/filter';
import {
  clearPaginationOptions,
  setPaginationAction,
  setPaginationOptions,
  setPaginationOptionsNoEmit,
  setPaginationSearch,
  setPaginationSearchSpinner,
} from './table.actions';
import { ITableState } from './table.interface';

const initState: ITableState = {
  paginationOptions: {
    page: 0,
    size: 10,
    order: OrderDirection.ASC,
    orderBy: '',
    search: '',
  },
  dispatchAction: undefined,
  searchSpinner: false,
};

export const tableReducer = createReducer(initState, (builder) =>
  builder
    .addCase(setPaginationOptions, (state, action) => {
      state.paginationOptions = {
        ...state.paginationOptions,
        ...action.payload,
      };
    })
    .addCase(setPaginationOptionsNoEmit, (state, action) => {
      state.paginationOptions = {
        ...state.paginationOptions,
        ...action.payload,
      };
    })
    .addCase(setPaginationSearch, (state, action) => {
      state.paginationOptions = {
        ...state.paginationOptions,
        search: action.payload,
      };
      state.searchSpinner = true;
    })
    .addCase(setPaginationAction, (state, action) => {
      state.dispatchAction = action.payload;
    })
    .addCase(clearPaginationOptions, (state) => {
      state.paginationOptions = initState.paginationOptions;
    })
    .addCase(setPaginationSearchSpinner, (state, action) => {
      state.searchSpinner = action.payload;
    }),
);
