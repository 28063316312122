/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { FormControl, SelectProps } from '@material-ui/core';
import { ChangeEvent, Component } from 'react';
import { FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { customInputStyles, SelectDiv, StyledSelect } from './CustomInput.style';

interface IProps {
  label: string;
  error: string;
  touched?: boolean;
  inputProps: FieldInputProps<unknown, HTMLElement>;
  testId?: string;
  rows: Array<{ value: string; name: string }>;
  width?: string;
  selectProps?: SelectProps;
  onChange?: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
}

export class CustomSelect extends Component<IProps & WithTranslation> {
  render(): JSX.Element {
    const { t, error, touched, testId, inputProps, rows, width, onChange } = this.props;

    const selectStyle = css`
      width: ${width ? width : 'auto'};
      margin-top: 8px;
      margin-bottom: 4px;
      background-color: #fff;
    `;

    return (
      <div css={customInputStyles.root}>
        <SelectDiv>
          <span css={customInputStyles.label}>{t(this.props.label)}</span>
          <FormControl margin="dense">
            <StyledSelect
              {...this.props.selectProps}
              css={[error && touched ? customInputStyles.outlineError : {}, selectStyle]}
              variant="outlined"
              margin="dense"
              onChange={onChange}
              native={this.props.selectProps?.native ? this.props.selectProps?.native : true}
              inputProps={{
                ...inputProps,
                'data-testid': testId,
                placeholder: 'choose...',
              }}
            >
              {!this.props.inputProps.value && <option value={undefined}></option>}
              {rows.map((row, index) => {
                return (
                  <option value={row.value} key={index}>
                    {row.name}
                  </option>
                );
              })}
            </StyledSelect>
          </FormControl>
        </SelectDiv>

        {error && touched && (
          <span css={customInputStyles.textError} data-testid={`${testId}Error`}>
            {t(error)}
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(CustomSelect);
