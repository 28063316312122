/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box } from '@material-ui/core';
import { Logout } from 'components/LoginHeader/Buttons/Logout';
import { Manual } from 'components/LoginHeader/Buttons/Manual';
import { ReleaseNotes } from 'components/LoginHeader/Buttons/ReleaseNotes';
import LoginHeader from 'components/LoginHeader/LoginHeader';
import Navbar from 'components/Navbar/Navbar';
import { Roles } from 'enums/roles';
import { mainRoutes } from 'layouts/main/routes';
import { Component, Dispatch, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AppDispatch, AppState } from 'store';
import { logoutUser } from 'store/user/user.actions';
import { getAuthSelector, getUserDataSelector } from 'store/user/user.selectors';
import { IRoute } from 'types/route';
import { checkRoles } from 'utils/checkRoles';
import { AuthenticationResult, RefreshTokenResult } from 'utils/restApplicationClient';

// will change to user credentials later
interface IStateProps {
  isAuth: boolean;
  data: AuthenticationResult & RefreshTokenResult;
}

interface IDispatchProps {
  logout: () => void;
}

export class MainLayoutComponent extends Component<IDispatchProps & IStateProps> {
  logout = (): void => {
    this.props.logout();
  };

  handleOpenLink = (url: string) => (): void => {
    window.open(url, '_blank');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderRoute(route: IRoute, props: RouteComponentProps<any>): JSX.Element {
    const { component: Component, roles } = route;
    const isAllowed = checkRoles(roles, (this.props.data?.roles as Roles[]) || roles);
    return isAllowed ? <Component {...props} /> : <Redirect to="/users" />;
  }

  render(): JSX.Element {
    return (
      <Fragment>
        <LoginHeader>
          <Box>
            <ReleaseNotes onClick={this.handleOpenLink} />
            <Manual onClick={this.handleOpenLink} />
            <Logout onClick={this.logout} />
          </Box>
        </LoginHeader>
        <Navbar pages={mainRoutes} />
        <Switch>
          {mainRoutes.map((route) => {
            return (
              <Route
                exact={route.exact}
                key={route.pathname}
                path={route.pathname}
                render={(props): JSX.Element => this.renderRoute(route, props)}
              />
            );
          })}
          <Route path="/" render={(): JSX.Element => <Redirect to="/users" />} />
        </Switch>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  logout: (): void => dispatch(logoutUser()),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  isAuth: getAuthSelector(state),
  data: getUserDataSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayoutComponent);
