import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';
import { IPaginationOptions } from 'types/api';
import { TableActionTypes } from './table.actions.types';
import { ITablePaginationOptions } from './table.interface';

export const setPaginationOptions = createAction<ITablePaginationOptions>(TableActionTypes.setPaginationOptions);

export const setPaginationOptionsNoEmit = createAction<ITablePaginationOptions>(
  TableActionTypes.setPaginationOptionsNoEmit,
);

export const clearPaginationOptions = createAction(TableActionTypes.clearPaginationOptions);

export const setPaginationSearch = createAction<string>(TableActionTypes.setPaginationSearch);

export const setPaginationAction = createAction<ActionCreatorWithPayload<IPaginationOptions> | undefined>(
  TableActionTypes.setPaginationAction,
);

export const setPaginationSearchSpinner = createAction<boolean>(TableActionTypes.setPaginationSearchSpinner);
