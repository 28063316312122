import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, Reducer } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { api } from 'utils/axiosInstance';
import browserHistory from 'utils/browserHistory';
import { administratorEpics } from './administrator/administrator.epics';
import { administratorReducer } from './administrator/administrator.reducer';
import { clientEpics } from './client/client.epics';
import { clientReducer } from './client/client.reducer';
import { notifyReducer } from './notify/notify.reducer';
import { spinnerEpics } from './spinner/spinner.epics';
import { spinnerReducer } from './spinner/spinner.reducer';
import { tableEpics } from './table/table.epics';
import { tableReducer } from './table/table.reducer';
import { logoutUserSuccess } from './user/user.actions';
import { userEpics } from './user/user.epics';
import { userReducer } from './user/user.reducer';

const appReducer = combineReducers({
  user: userReducer,
  notify: notifyReducer,
  client: clientReducer,
  table: tableReducer,
  administrator: administratorReducer,
  spinner: spinnerReducer,
});

const rootReducer: Reducer = (state, action) => {
  if (action.type === logoutUserSuccess().type) {
    state = undefined;
  }

  return appReducer(state, action);
};

const epicMiddleware = createEpicMiddleware({
  dependencies: { browserHistory, api },
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [epicMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
});

epicMiddleware.run(combineEpics(userEpics, clientEpics, tableEpics, administratorEpics, spinnerEpics));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (process.env.NODE_ENV !== 'production' && module.hot) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  module.hot.accept('./', () => store.replaceReducer(rootReducer));
}

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = ReturnType<typeof store.dispatch>;
export default store;
