/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserRouteParameterEnum } from 'enums/userRouteParameter';
import { createForm, FormApi, Unsubscribe } from 'final-form';
import { Component, Fragment } from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { getconnectDataSpinnerSelector, getConnectToXtmSpinnerSelector } from 'store/spinner/spinner.selectors';
import { clearUserErrors, xtmConnect, xtmConnectUser, xtmGetConnectData } from 'store/user/user.actions';
import {
  getUserErrorsSelector,
  getUserSelector,
  getXtmAuthorizationIdSelector,
  getXtmConnectDataSelector,
} from 'store/user/user.selectors';
import { composeValidators, fetchValidatorObject, required } from 'utils/customValidators';
import { UserDTO } from 'utils/restApplicationClient';
import { XTMConnectionParametersDTO, XTMConnectRequest } from 'utils/restApplicationClientTypeOverrides';
import CustomField from '../../Input/CustomField';
import { customInputStyles } from '../../Input/CustomInput.style';
import { AccordionDiv, accordionStyles } from '../Accordion.style';
import AccordionIcon from '../AccordionIcon';

interface IStateProps {
  errors: { [key: string]: string };
  authorizationId?: string;
  user?: UserDTO;
  connectData?: XTMConnectionParametersDTO;
  connectDataSpinner: boolean;
  connectToXtmSpinner: boolean;
}

interface IDispatchProps {
  xtmConnect: (payload: XTMConnectRequest) => AppDispatch;
  xtmConnectUser: (payload: { userId: string; xtmParameters: XTMConnectRequest }) => AppDispatch;
  clearUserErrors: () => AppDispatch;
  getConnectData: (payload: string) => AppDispatch;
}

interface IState {
  connected: boolean;
  submitValues: XTMConnectRequest;
}

interface IMatchParams {
  id?: string;
  type?: UserRouteParameterEnum;
}

type PropType = FieldRenderProps<unknown, HTMLElement> &
  WithTranslation &
  IStateProps &
  IDispatchProps &
  RouteComponentProps<IMatchParams>;

export class XtmConnectAccordion extends Component<PropType, IState> {
  unsubscribe: Unsubscribe;
  constructor(props: PropType) {
    super(props);
    this.state = {
      connected: false,
      submitValues: {
        userUuidToCopyPassword: null,
        xtmUrl: '',
        xtmCompanyName: '',
        password: '',
        username: '',
      },
    };
    this.form = createForm({
      onSubmit: this.onSubmit,
    });
    this.unsubscribe = this.form.subscribe(
      () => {
        if (this.props.errors['xtmConnect']) {
          this.props.clearUserErrors();
        }
      },
      { values: true },
    );
  }

  componentDidMount(): void {
    const {
      match: {
        params: { id: userId },
      },
      getConnectData,
    } = this.props;
    if (userId) {
      getConnectData(userId);
    }
  }

  componentDidUpdate(previousProps: PropType): void {
    const {
      authorizationId,
      errors: { xtmConnect },
      connectData,
      match: {
        params: { id: userId, type },
      },
      xtmConnectUser,
    } = this.props;
    const { connected } = this.state;
    if (previousProps.authorizationId !== authorizationId && authorizationId) {
      this.props.input.onChange(authorizationId);
      this.setState({ connected: true });
    } else if (xtmConnect && connected) {
      this.props.input.onChange(undefined);
      this.setState({ connected: false });
    }
    if (previousProps.connectData === undefined && connectData && userId && type === UserRouteParameterEnum.edit) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { xtmUsername, xtmPassword, ...rest } = connectData;
      xtmConnectUser({
        userId,
        xtmParameters: {
          password: null,
          username: connectData.xtmUsername,
          userUuidToCopyPassword: userId,
          ...rest,
        },
      });
    }
  }

  componentWillUnmount(): void {
    this.unsubscribe();
  }

  form: FormApi<XTMConnectRequest, Partial<Record<string, unknown>>>;

  onSubmit = (values: XTMConnectRequest): void => {
    const {
      match: {
        params: { id: userId, type },
      },
      connectData,
    } = this.props;
    const isNewPassword = values.password !== connectData?.xtmPassword;
    if (userId) {
      const connectionParameters = {
        ...values,
        password: isNewPassword ? values.password : null,
        userUuidToCopyPassword: isNewPassword ? null : userId,
      };
      if (userId && type === UserRouteParameterEnum.edit) {
        this.props.xtmConnectUser({
          userId,
          xtmParameters: {
            ...connectionParameters,
          },
        });
      } else if (userId && type === UserRouteParameterEnum.duplicate) {
        this.props.xtmConnect({
          ...connectionParameters,
        });
      }
    } else {
      this.props.xtmConnect(values);
    }
    this.setState({ submitValues: values });
  };

  render(): JSX.Element {
    const { t, errors, connectData, connectDataSpinner, connectToXtmSpinner, meta } = this.props;
    return (
      <AccordionDiv>
        <span css={accordionStyles.accordionLabel}>{t('users.xtm.connect')}</span>
        <Accordion css={accordionStyles.root}>
          <AccordionSummary css={accordionStyles.summary} expandIcon={<ExpandMoreIcon />}>
            <AccordionIcon
              error={Boolean(meta.error)}
              touched={meta.touched}
              connected={this.state.connected}
              spinner={connectDataSpinner}
            />
            <span css={customInputStyles.label}>
              {this.state.connected ? t('users.connected') : t('users.disconnected')}
            </span>
          </AccordionSummary>
          <AccordionDetails css={accordionStyles.details}>
            {errors['xtmConnect'] && (
              <span css={accordionStyles.accordionErrorText} data-testid={'xtmAccordionError'}>
                {t(errors['xtmConnect'])}
              </span>
            )}
            <Form
              form={this.form}
              onSubmit={this.onSubmit}
              validate={fetchValidatorObject(
                errors['xtmConnect'],
                (this.state.submitValues as unknown) as Record<string, unknown>,
              )}
              subscription={{ pristine: true, submitting: true }}
              render={({ handleSubmit, submitting }): JSX.Element => (
                <Fragment>
                  <Field
                    name="xtmUrl"
                    validate={composeValidators([required])}
                    initialValue={connectData && connectData.xtmUrl}
                  >
                    {({ input, meta }): JSX.Element => (
                      <CustomField
                        label="users.xtm.url"
                        error={meta.error}
                        touched={meta.touched}
                        inputProps={input}
                        testId="xtmUrlInput"
                      />
                    )}
                  </Field>
                  <Field
                    name="xtmCompanyName"
                    validate={composeValidators([required])}
                    initialValue={connectData && connectData.xtmCompanyName}
                  >
                    {({ input, meta }): JSX.Element => (
                      <CustomField
                        label="users.xtm.companyName"
                        error={meta.error}
                        touched={meta.touched}
                        inputProps={input}
                        testId="xtmCompanyNameInput"
                      />
                    )}
                  </Field>
                  <Field
                    name="username"
                    validate={composeValidators([required])}
                    initialValue={connectData && connectData.xtmUsername}
                  >
                    {({ input, meta }): JSX.Element => (
                      <CustomField
                        label="users.xtm.user"
                        error={meta.error}
                        touched={meta.touched}
                        inputProps={input}
                        testId="xtmUserInput"
                        disableAutoComplete={true}
                      />
                    )}
                  </Field>
                  <Field
                    name="password"
                    validate={composeValidators([required])}
                    initialValue={connectData && connectData.xtmPassword}
                  >
                    {({ input, meta }): JSX.Element => (
                      <CustomField
                        label="users.xtm.password"
                        error={meta.error}
                        touched={meta.touched}
                        inputProps={input}
                        testId="xtmPasswordInput"
                        type="password"
                        disableAutoComplete={true}
                      />
                    )}
                  </Field>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={submitting || connectToXtmSpinner}
                    onClick={handleSubmit}
                    data-testid="xtmSubmitButton"
                    fullWidth
                  >
                    {connectToXtmSpinner ? (
                      <CircularProgress data-testid="xtmSpinner" color="secondary" size={24} />
                    ) : (
                      t('users.xtm.connect')
                    )}
                  </Button>
                </Fragment>
              )}
            />
          </AccordionDetails>
        </Accordion>
      </AccordionDiv>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  errors: getUserErrorsSelector(state),
  authorizationId: getXtmAuthorizationIdSelector(state),
  user: getUserSelector(state),
  connectData: getXtmConnectDataSelector(state),
  connectDataSpinner: getconnectDataSpinnerSelector(state),
  connectToXtmSpinner: getConnectToXtmSpinnerSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  xtmConnect: (payload: XTMConnectRequest): AppDispatch => dispatch(xtmConnect(payload)),
  clearUserErrors: (): AppDispatch => dispatch(clearUserErrors()),
  getConnectData: (payload: string): AppDispatch => dispatch(xtmGetConnectData(payload)),
  xtmConnectUser: (payload: { userId: string; xtmParameters: XTMConnectRequest }): AppDispatch =>
    dispatch(xtmConnectUser(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(XtmConnectAccordion)));
