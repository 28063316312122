import administrator from './administrator.json';
import auth from './auth.json';
import client from './client.json';
import common from './common.json';
import table from './table.json';
import users from './users.json';
import validator from './validator.json';

const en = {
  translation: {
    common,
    client,
    table,
    administrator,
    validator,
    auth,
    users,
  },
};

export default en;
