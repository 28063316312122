// @flow
import { useSnackbar, withSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { closeAlert } from 'store/notify/notify.actions';
import { IErrorNofitication } from 'store/notify/notify.interface';

let displayed: number[] = [];

export function getDisplayedKeys(): number[] {
  return displayed;
}

const NotifyAlert: React.FC = () => {
  const dispatch = useDispatch<Dispatch<AppDispatch>>();
  const notifications = useSelector<AppState, IErrorNofitication[]>((store) => store.notify.notifications);
  const { t } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.map(({ key, message, options = {} }) => {
      if (displayed.includes(key)) return;

      enqueueSnackbar(t(message), {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (_event, myKey) => {
          dispatch(closeAlert({ key: Number(myKey) }));
          displayed = [...displayed.filter((dp) => dp !== key)];
        },
      });

      displayed = [...displayed, key];
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default withSnackbar(NotifyAlert);
