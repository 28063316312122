/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Button, CircularProgress } from '@material-ui/core';
import { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from 'store';
import { getFetchSpinnerSelector } from 'store/spinner/spinner.selectors';

interface IProps {
  submitting: boolean;
  backTo: string;
}

interface IStateProps {
  fetchSpinner: boolean;
}

const ButtonContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 40%;
  margin: 0 auto;
  margin-top: 20px !important;
  & button {
    min-width: 100px;
  }
`;

const ButtonLink = styled(Link)`
  text-decoration: none;
`;

class ButtonContainer extends PureComponent<WithTranslation & IProps & IStateProps> {
  render(): JSX.Element {
    const { t, submitting, backTo, fetchSpinner } = this.props;
    return (
      <ButtonContainerDiv>
        <ButtonLink to={backTo} style={{ textDecoration: 'none' }}>
          <Button variant="contained" data-testid="backButton">
            {t('common.cancel')}
          </Button>
        </ButtonLink>
        <Button
          variant="contained"
          color="secondary"
          disabled={submitting || fetchSpinner}
          type="submit"
          data-testid="submitButton"
        >
          {fetchSpinner ? <CircularProgress color="secondary" size={20} /> : t('common.save')}
        </Button>
      </ButtonContainerDiv>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  fetchSpinner: getFetchSpinnerSelector(state),
});

export default connect(mapStateToProps)(withTranslation()(ButtonContainer));
