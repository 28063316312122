import CustomTableContainer from 'components/Table/CustomTable';
import TableToolbar from 'components/TableToolbar/TableToolbar';
import { OrderDirection } from 'enums/filter';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { getClientsPageable } from 'store/client/client.actions';
import { getClientsPageableSelector } from 'store/client/client.selectors';
import { setPaginationOptionsNoEmit } from 'store/table/table.actions';
import { ITablePaginationOptions } from 'store/table/table.interface';
import { ClientDTO, Page } from 'utils/restApplicationClient';

interface IStateProps {
  clientsPageable?: Page<ClientDTO>;
  fetchSpinnerOverride?: boolean;
}

interface IDispatchProps {
  setPaginationOptions: (payload: ITablePaginationOptions) => AppDispatch;
}

const TestTable = CustomTableContainer<ClientDTO>();

type IProps = IStateProps & RouteComponentProps & IDispatchProps;

export class ClientsContainer extends Component<IProps> {
  headers = [
    { label: 'common.id', id: 'id' },
    { label: 'common.clientName', id: 'clientName' },
    { label: 'common.status', id: 'status' },
    { label: 'common.creationDate', id: 'createdAt' },
    { label: 'common.lastModificationDate', id: 'modifiedAt' },
  ];
  onEdit = (row: ClientDTO): void => {
    this.props.history.push(`client/edit/${row.id}`);
  };

  onAdd = (): void => {
    this.props.history.push('client/add');
  };

  componentDidMount(): void {
    this.props.setPaginationOptions({
      order: OrderDirection.ASC,
      orderBy: 'clientName',
    });
  }

  render(): JSX.Element {
    const { clientsPageable, fetchSpinnerOverride } = this.props;
    return (
      <div>
        <TableToolbar buttonLabelKey="client.add" onAdd={this.onAdd} />
        <TestTable
          headers={this.headers}
          onEdit={this.onEdit}
          paginationAction={getClientsPageable}
          pageable={clientsPageable}
          search={''}
          fetchSpinnerOverride={fetchSpinnerOverride}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  clientsPageable: getClientsPageableSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setPaginationOptions: (payload: ITablePaginationOptions): AppDispatch =>
    dispatch(setPaginationOptionsNoEmit(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientsContainer));
