/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, CircularProgress } from '@material-ui/core';
import FormDiv from 'components/Form/Div/FormDiv';
import FormTitle from 'components/Form/Title/FormTitle';
import { resetPasswordRouteParameterEnum } from 'enums/resetPasswordRouteParameter';
import { ValidationErrors } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppState } from 'store';
import { getFetchSpinnerSelector } from 'store/spinner/spinner.selectors';
import { loginContainerStyles } from '../../containers/Login/LoginContainer.style';
import { changePassword, changeUserPassword } from '../../store/user/user.actions';
import { comparePass, composeValidators, password, required } from '../../utils/customValidators';
import { ChangePasswordDTO } from '../../utils/restApplicationClientTypeOverrides';
import CustomField from '../Form/Input/CustomField';

interface IResetFormValues {
  newPassword: string;
  confirmPassword: string;
}

interface IResetFormProps {
  token: string | null;
}

export const ChangePasswordForm = (props: IResetFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const parameters = new URLSearchParams(location.search);
  const type = parameters.get('type');
  const fetchSpinner = useSelector((state: AppState) => getFetchSpinnerSelector(state));
  const onSubmit = ({ newPassword }: IResetFormValues): void => {
    if (props.token && type === resetPasswordRouteParameterEnum.admin) {
      dispatch(
        changePassword({
          newPassword,
          resetPasswordToken: props.token,
        } as ChangePasswordDTO),
      );
    } else if (props.token && type === resetPasswordRouteParameterEnum.user) {
      dispatch(
        changeUserPassword({
          newPassword,
          resetPasswordToken: props.token,
        } as ChangePasswordDTO),
      );
    }
  };

  return (
    <FormDiv width={500}>
      <FormTitle text="auth.password.reset" width={500} />
      <Form
        onSubmit={onSubmit}
        validate={(values): ValidationErrors | undefined => comparePass(values)}
        render={({ handleSubmit, submitting }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <Field name="newPassword" validate={composeValidators([required, password])}>
              {({ input, meta }): JSX.Element => (
                <CustomField
                  label={'auth.password.new'}
                  error={meta.error}
                  touched={meta.touched}
                  inputProps={{ ...input }}
                  type="password"
                  testId="passwordInput"
                />
              )}
            </Field>
            <Field name="confirmPassword" validate={composeValidators([required, password])}>
              {({ input, meta }): JSX.Element => (
                <div>
                  <CustomField
                    label={'auth.password.confirm'}
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={{ ...input }}
                    type="password"
                    testId="confirmPasswordInput"
                  />
                </div>
              )}
            </Field>
            <Button
              variant="contained"
              color="secondary"
              disabled={submitting || fetchSpinner}
              type="submit"
              css={loginContainerStyles.button}
              data-testid="changePassButton"
            >
              {fetchSpinner ? <CircularProgress size={24} color="secondary" /> : t('auth.password.change')}
            </Button>
          </form>
        )}
      />
    </FormDiv>
  );
};
