import React, { Component } from 'react';
import { Trans } from 'react-i18next';

interface IProps {
  from: number;
  to: number;
  count: number;
}

class PaginationTransLabel extends Component<IProps> {
  render(): JSX.Element {
    const { from, to, count } = this.props;
    return (
      <React.Fragment>
        {count < 0 ? (
          <Trans i18nKey="table.labelDisplayedRowsMore" values={{ from, to }} />
        ) : (
          <Trans i18nKey="table.labelDisplayedRows" values={{ from, to, count }} />
        )}
      </React.Fragment>
    );
  }
}

export default PaginationTransLabel;
