/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
// Generated using typescript-generator version 2.24.612 on 2024-05-17 05:25:27.

import { TranslateRequestDTO, AuthenticationRequest, ChangePasswordDTO, CreateAdministratorDTO, UpdateAdministratorDTO, CreateUserDTO, UpdateUserDTO, XTMConnectionParametersDTO, XTMConnectRequest, GetTemplatesDTO, GetCustomersDTO } from "./restApplicationClientTypeOverrides";

export interface AbstractCreateOrUpdateUserDTO extends Serializable {
    firstName: string;
    lastName: string;
    interfaceLanguage: string;
    email: string;
    clientUUID: string;
    xtmCustomerId: number;
    xtmTemplateId: number;
    status: boolean;
    forceDefaultCustomerSettings: boolean;
    defaultTranslationSourceLanguage: string;
}

export interface TranslationProduct extends Serializable {
    productId: string;
    productType: ProductType;
}

export interface Serializable {
}

export interface AdministratorDTO extends BaseDTO, Serializable {
    email: string;
    status: boolean;
    clientName: string;
    client: ClientDTO;
}

export interface AuthenticationResult {
    accessToken: string;
    ttl: number;
    refreshToken: string;
    username: string;
    roles: string[];
}

export interface RefreshTokenResult {
    accessToken: string;
    ttl: number;
    refreshToken: string;
    username: string;
    roles: string[];
}

export interface CreateOrUpdateClientDTO extends Serializable {
    clientName: string;
    status: boolean;
    languageMappings: LanguageMappingDTO[];
}

export interface ClientDTO extends BaseDTO, Serializable {
    clientName: string;
    status: boolean;
    languageMappings: LanguageMappingDTO[];
}

export interface HubspotConnectRequest extends Serializable {
    code: string;
}

export interface XTMConnectDTO extends Serializable {
    xtmAuthorizationId: string;
}

export interface ActiveProjectSearchDTO extends Serializable {
    sourceLanguage: string;
    targetLanguages: string[];
    customerId: number;
}

export interface XTMProjectVerifyDTO extends Serializable {
    customerId: number;
    templateId: number;
    sourceLanguage: string;
    targetLanguages: string[];
    entries: TranslationProduct[];
}

export interface XTMProjectEntryVerifyDTO extends Serializable {
    entries: XTMProjectEntriesVerifyDTO[];
    haveProjects: boolean;
}

export interface MassOperationRequest extends Serializable {
    jobsIds: string[];
    operation: JobOperation;
}

export interface ResetPasswordDTO extends Serializable {
    email: string;
    language: string;
}

export interface VerifyTokenDTO extends Serializable {
    token: string;
}

export interface UserDTO extends BaseDTO {
    email: string;
    status: boolean;
    clientName: string;
    interfaceLanguage: string;
    client: ClientDTO;
    xtmProperties: UserXTMPropertiesDTO;
    userSpecification: UserSpecificationDTO;
}

export interface UserConfigurationDTO extends Serializable {
    forceDefaultCustomerSettings: boolean;
    defaultLanguage: string;
}

export interface BaseDTO extends Serializable {
    id: string;
    createdAt: Date;
    modifiedAt: Date;
}

export interface Page<T> extends Slice<T> {
    totalPages: number;
    totalElements: number;
}

export interface LanguageMappingDTO extends Serializable {
    xtmCode: string;
    hubspotCode: string;
}

export interface HubspotProductDTO extends Serializable {
    id: string;
    name: string;
    authorName: string;
    type: ProductType;
    updated: Date;
    screenshotPreviewUrl: string;
    currentState: string;
    sourceLanguages: string[];
    availableTargetLanguages: string[];
}

export interface PageLanguageVariationsDTO extends Serializable {
    pageId: number;
    sourceLanguages: string[];
}

export interface XTMProjectDTO extends Serializable {
    id: number;
    name: string;
    status: string;
    activityEnum: ActivityEnum;
}

export interface XTMProjectEntriesVerifyDTO extends Serializable {
    entryId: string;
    entryName: string;
    projectId: number;
}

export interface XTMCustomerDTO extends Serializable {
    xtmCustomerId: number;
    name: string;
}

export interface HubspotLanguageDTO extends Serializable {
    code: string;
    name: string;
}

export interface XTMTemplateDTO extends Serializable {
    xtmTemplateId: number;
    name: string;
}

export interface XTMJobDTO extends BaseDTO {
    projectName: string;
    sourceLanguage: string;
    targetLanguage: string;
    errorMessage: string;
    errorMessagesDetails: string[];
    status: XTMJobStatus;
    workflowStep: string;
    jobProgress: number;
    dueDate: Date;
    projectId: number;
    customerId: number;
    templateId: number;
    contentName: string;
}

export interface UserOverviewDTO extends BaseDTO {
    email: string;
    status: boolean;
    clientName: string;
}

export interface UserXTMPropertiesDTO extends BaseDTO {
    xtmCustomerId: number;
    xtmTemplateId: number;
}

export interface UserSpecificationDTO extends BaseDTO {
    firstName: string;
    lastName: string;
    defaultTranslationSourceLanguage: string;
    generateAndUploadScreenshots: boolean;
    forceDefaultCustomerSettings: boolean;
    automaticImport: boolean;
    importPerJobs: boolean;
    importAllJobs: boolean;
    importInterval: number;
}

export interface Sort extends Streamable<Order>, Serializable {
    sorted: boolean;
    unsorted: boolean;
}

export interface Pageable {
    offset: number;
    sort: Sort;
    paged: boolean;
    unpaged: boolean;
    pageNumber: number;
    pageSize: number;
}

export interface Slice<T> extends Streamable<T> {
    size: number;
    content: T[];
    number: number;
    sort: Sort;
    numberOfElements: number;
    pageable: Pageable;
    first: boolean;
    last: boolean;
}

export interface Streamable<T> extends Iterable<T>, Supplier<Stream<T>> {
    empty: boolean;
}

export interface Order extends Serializable {
    direction: Direction;
    property: string;
    ignoreCase: boolean;
    nullHandling: NullHandling;
    ascending: boolean;
    descending: boolean;
}

export interface Iterable<T> {
}

export interface Supplier<T> {
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {
}

export interface BaseStream<T, S> extends AutoCloseable {
    parallel: boolean;
}

export interface AutoCloseable {
}

export interface RestApplication<O> {

    /**
     * HTTP GET /
     * Java method: cloud.xtm.hubspot.backend.common.app.api.RootController.getRoot
     */
    getRoot(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api
     * Java method: cloud.xtm.hubspot.backend.common.app.api.RootController.getApiRoot
     */
    getApiRoot(options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/administrators
     * Java method: cloud.xtm.hubspot.backend.administrators.app.api.ClientAdministratorController.createAdministrator
     */
    createAdministrator(createAdministrator: CreateAdministratorDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/administrators/pageable
     * Java method: cloud.xtm.hubspot.backend.administrators.app.api.ClientAdministratorController.getAllAdministrators
     */
    getAllAdministrators(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<AdministratorDTO>>;

    /**
     * HTTP DELETE /api/administrators/{administratorId}
     * Java method: cloud.xtm.hubspot.backend.administrators.app.api.ClientAdministratorController.deleteAdministrator
     */
    deleteAdministrator(administratorId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/administrators/{administratorId}
     * Java method: cloud.xtm.hubspot.backend.administrators.app.api.ClientAdministratorController.editAdministrator
     */
    editAdministrator(administratorId: string, updateAdministrator: UpdateAdministratorDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/administrators/{administratorId}
     * Java method: cloud.xtm.hubspot.backend.administrators.app.api.ClientAdministratorController.getAdministrator
     */
    getAdministrator(administratorId: string, options?: O): RestResponse<AdministratorDTO>;

    /**
     * HTTP POST /api/auth/admin
     * Java method: cloud.xtm.hubspot.backend.auth.app.api.AuthController.authenticateAdmin
     */
    authenticateAdmin(authRequest: AuthenticationRequest, options?: O): RestResponse<AuthenticationResult>;

    /**
     * HTTP GET /api/auth/refreshToken
     * Java method: cloud.xtm.hubspot.backend.auth.app.api.AuthController.refreshToken
     */
    refreshToken(options?: O): RestResponse<RefreshTokenResult>;

    /**
     * HTTP POST /api/auth/user
     * Java method: cloud.xtm.hubspot.backend.auth.app.api.AuthController.authenticateUser
     */
    authenticateUser(authRequest: AuthenticationRequest, options?: O): RestResponse<AuthenticationResult>;

    /**
     * HTTP POST /api/client
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.createClient
     */
    createClient(createClient: CreateOrUpdateClientDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/client
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client(options?: O): RestResponse<ClientDTO[]>;

    /**
     * HTTP GET /api/client/pageable
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client_pageable(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<ClientDTO>>;

    /**
     * HTTP DELETE /api/client/{clientId}
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.deleteClient
     */
    deleteClient(clientId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/client/{clientId}
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.editClient
     */
    editClient(clientId: string, updateClient: CreateOrUpdateClientDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/client/{clientId}
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.getClient
     */
    getClient(clientId: string, options?: O): RestResponse<ClientDTO>;

    /**
     * HTTP GET /api/content/customers
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.content.api.XTMAddContentController.getXTMCustomers
     */
    getXTMCustomers$GET$api_content_customers(options?: O): RestResponse<GetCustomersDTO>;

    /**
     * HTTP POST /api/content/entries/verify
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.content.api.XTMAddContentController.verify
     */
    verify(xtmProject: XTMProjectVerifyDTO, options?: O): RestResponse<XTMProjectEntryVerifyDTO>;

    /**
     * HTTP POST /api/content/projects/active
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.content.api.XTMAddContentController.getActiveXTMProjects
     */
    getActiveXTMProjects(activeProjectSearch: ActiveProjectSearchDTO, options?: O): RestResponse<XTMProjectDTO[]>;

    /**
     * HTTP GET /api/content/templates/{xtmCustomerId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.content.api.XTMAddContentController.getXTMTemplatesForExistingUser
     */
    getXTMTemplatesForExistingUser$GET$api_content_templates_xtmCustomerId(xtmCustomerId: number, options?: O): RestResponse<GetTemplatesDTO>;

    /**
     * HTTP GET /api/hubspot/blog-post/pageable
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.blog.api.HubspotBlogPostController.findHubspotBlogPosts
     */
    findHubspotBlogPosts(queryParams?: { page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<HubspotProductDTO>>;

    /**
     * HTTP POST /api/hubspot/connect
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.auth.app.api.HubspotConnectController.connect
     */
    connect(connectRequest: HubspotConnectRequest, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/hubspot/connect/refresh
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.auth.app.api.HubspotConnectController.refresh
     */
    refresh(options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/hubspot/connect/status
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.auth.app.api.HubspotConnectController.status
     */
    status(options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/hubspot/email/pageable
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.email.api.HubspotEmailController.findHubspotEmails
     */
    findHubspotEmails(queryParams?: { page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<HubspotProductDTO>>;

    /**
     * HTTP GET /api/hubspot/form/pageable
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.form.api.HubspotFormController.findHubspotForms
     */
    findHubspotForms(queryParams?: { page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<HubspotProductDTO>>;

    /**
     * HTTP POST /api/hubspot/page/languages
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.page.api.HubspotPageController.getLanguageVariations
     */
    getLanguageVariations(pageIds: string[], options?: O): RestResponse<PageLanguageVariationsDTO[]>;

    /**
     * HTTP GET /api/hubspot/page/pageable
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.page.api.HubspotPageController.findHubspotPages
     */
    findHubspotPages(queryParams?: { page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<HubspotProductDTO>>;

    /**
     * HTTP GET /api/jobs
     * Java method: cloud.xtm.hubspot.backend.jobs.app.api.JobsController.findAllPaged
     */
    findAllPaged(queryParams?: { forceRefresh?: boolean; search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<XTMJobDTO>>;

    /**
     * HTTP POST /api/jobs
     * Java method: cloud.xtm.hubspot.backend.jobs.app.api.JobsController.performOperation
     */
    performOperation(request: MassOperationRequest, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/jobs/operation-applicable-for
     * Java method: cloud.xtm.hubspot.backend.jobs.app.api.JobsController.findJobsIdsByOperationType
     */
    findJobsIdsByOperationType(request: MassOperationRequest, options?: O): RestResponse<string[]>;

    /**
     * HTTP POST /api/notifications/admin/send-reset-password-email
     * Java method: cloud.xtm.hubspot.backend.notification.app.api.NotificationController.sendResetPasswordEmailForAdmin
     */
    sendResetPasswordEmailForAdmin(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/notifications/change-password
     * Java method: cloud.xtm.hubspot.backend.notification.app.api.NotificationController.changePassword
     */
    changePassword(changePassword: ChangePasswordDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/notifications/send-reset-password-email
     * Java method: cloud.xtm.hubspot.backend.notification.app.api.NotificationController.sendResetPasswordEmail
     */
    sendResetPasswordEmail(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/notifications/verify-reset-password-token
     * Java method: cloud.xtm.hubspot.backend.notification.app.api.NotificationController.verifyResetPasswordToken
     */
    verifyResetPasswordToken(verifyToken: VerifyTokenDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/translations/translate
     * Java method: cloud.xtm.hubspot.backend.translation.app.api.XTMTranslationController.translate
     */
    translate(translateRequest: TranslateRequestDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/users
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.createUser
     */
    createUser(createUser: CreateUserDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/users/import-configuration
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.importConfiguration
     */
    importConfiguration(options?: O): RestResponse<UserConfigurationDTO>;

    /**
     * HTTP GET /api/users/pageable
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.getAllUsers
     */
    getAllUsers(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<UserOverviewDTO>>;

    /**
     * HTTP DELETE /api/users/{userId}
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.deleteUser
     */
    deleteUser(userId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/users/{userId}
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.editUser
     */
    editUser(userId: string, updateUser: UpdateUserDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/users/{userId}
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.getUser
     */
    getUser(userId: string, options?: O): RestResponse<UserDTO>;

    /**
     * HTTP GET /api/xtm/callbacks/jobs/{clientId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.callback.app.api.XTMCallbackController.getJobFinishedCallback
     */
    getJobFinishedCallback(clientId: string, queryParams: { xtmJobId: number; xtmProjectId: number; }, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/xtm/callbacks/project/analysis/finished/{clientId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.callback.app.api.XTMCallbackController.analysisFinished
     */
    analysisFinished(clientId: string, queryParams: { xtmProjectId: number; }, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/xtm/connect
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.auth.app.api.XTMConnectController.connectToXtm
     */
    connectToXtm(xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO>;

    /**
     * HTTP GET /api/xtm/connect/{userId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.auth.app.api.XTMConnectController.getXTMConnectionParameters
     */
    getXTMConnectionParameters(userId: string, options?: O): RestResponse<XTMConnectionParametersDTO>;

    /**
     * HTTP POST /api/xtm/connect/{userId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.auth.app.api.XTMConnectController.reconnectToXtm
     */
    reconnectToXtm(userId: string, xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO>;

    /**
     * HTTP GET /api/xtm/customers
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomers
     */
    getXTMCustomers$GET$api_xtm_customers(queryParams: { xtmAuthId: string; }, options?: O): RestResponse<XTMCustomerDTO[]>;

    /**
     * HTTP GET /api/xtm/customers/{userId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomersForExistingUser
     */
    getXTMCustomersForExistingUser(userId: string, options?: O): RestResponse<XTMCustomerDTO[]>;

    /**
     * HTTP GET /api/xtm/languages/hubspot
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.languages.app.api.LanguageController.getHubspotLanguages
     */
    getHubspotLanguages(options?: O): RestResponse<HubspotLanguageDTO[]>;

    /**
     * HTTP GET /api/xtm/languages/xtm
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.languages.app.api.LanguageController.getXtmLanguagesCodes
     */
    getXtmLanguagesCodes(options?: O): RestResponse<string[]>;

    /**
     * HTTP GET /api/xtm/templates
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.templates.app.api.XTMTemplatesController.getXTMTemplates
     */
    getXTMTemplates(queryParams: { xtmAuthId: string; xtmCustomerId: number; }, options?: O): RestResponse<XTMTemplateDTO[]>;

    /**
     * HTTP GET /api/xtm/templates/{userId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.templates.app.api.XTMTemplatesController.getXTMTemplatesForExistingUser
     */
    getXTMTemplatesForExistingUser$GET$api_xtm_templates_userId(userId: string, queryParams: { xtmCustomerId: number; }, options?: O): RestResponse<XTMTemplateDTO[]>;
}

export interface HttpClient<O> {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: O; }): RestResponse<R>;
}

export class RestApplicationClient<O> implements RestApplication {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /
     * Java method: cloud.xtm.hubspot.backend.common.app.api.RootController.getRoot
     */
    getRoot(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding``, options: options });
    }

    /**
     * HTTP GET /api
     * Java method: cloud.xtm.hubspot.backend.common.app.api.RootController.getApiRoot
     */
    getApiRoot(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api`, options: options });
    }

    /**
     * HTTP POST /api/administrators
     * Java method: cloud.xtm.hubspot.backend.administrators.app.api.ClientAdministratorController.createAdministrator
     */
    createAdministrator(createAdministrator: CreateAdministratorDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/administrators`, data: createAdministrator, options: options });
    }

    /**
     * HTTP GET /api/administrators/pageable
     * Java method: cloud.xtm.hubspot.backend.administrators.app.api.ClientAdministratorController.getAllAdministrators
     */
    getAllAdministrators(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<AdministratorDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/administrators/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/administrators/{administratorId}
     * Java method: cloud.xtm.hubspot.backend.administrators.app.api.ClientAdministratorController.deleteAdministrator
     */
    deleteAdministrator(administratorId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/administrators/${administratorId}`, options: options });
    }

    /**
     * HTTP PUT /api/administrators/{administratorId}
     * Java method: cloud.xtm.hubspot.backend.administrators.app.api.ClientAdministratorController.editAdministrator
     */
    editAdministrator(administratorId: string, updateAdministrator: UpdateAdministratorDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/administrators/${administratorId}`, data: updateAdministrator, options: options });
    }

    /**
     * HTTP GET /api/administrators/{administratorId}
     * Java method: cloud.xtm.hubspot.backend.administrators.app.api.ClientAdministratorController.getAdministrator
     */
    getAdministrator(administratorId: string, options?: O): RestResponse<AdministratorDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/administrators/${administratorId}`, options: options });
    }

    /**
     * HTTP POST /api/auth/admin
     * Java method: cloud.xtm.hubspot.backend.auth.app.api.AuthController.authenticateAdmin
     */
    authenticateAdmin(authRequest: AuthenticationRequest, options?: O): RestResponse<AuthenticationResult> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/auth/admin`, data: authRequest, options: options });
    }

    /**
     * HTTP GET /api/auth/refreshToken
     * Java method: cloud.xtm.hubspot.backend.auth.app.api.AuthController.refreshToken
     */
    refreshToken(options?: O): RestResponse<RefreshTokenResult> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/auth/refreshToken`, options: options });
    }

    /**
     * HTTP POST /api/auth/user
     * Java method: cloud.xtm.hubspot.backend.auth.app.api.AuthController.authenticateUser
     */
    authenticateUser(authRequest: AuthenticationRequest, options?: O): RestResponse<AuthenticationResult> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/auth/user`, data: authRequest, options: options });
    }

    /**
     * HTTP POST /api/client
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.createClient
     */
    createClient(createClient: CreateOrUpdateClientDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/client`, data: createClient, options: options });
    }

    /**
     * HTTP GET /api/client
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client(options?: O): RestResponse<ClientDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/client`, options: options });
    }

    /**
     * HTTP GET /api/client/pageable
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client_pageable(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<ClientDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/client/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/client/{clientId}
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.deleteClient
     */
    deleteClient(clientId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/client/${clientId}`, options: options });
    }

    /**
     * HTTP PUT /api/client/{clientId}
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.editClient
     */
    editClient(clientId: string, updateClient: CreateOrUpdateClientDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/client/${clientId}`, data: updateClient, options: options });
    }

    /**
     * HTTP GET /api/client/{clientId}
     * Java method: cloud.xtm.hubspot.backend.clients.app.api.ClientController.getClient
     */
    getClient(clientId: string, options?: O): RestResponse<ClientDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/client/${clientId}`, options: options });
    }

    /**
     * HTTP GET /api/content/customers
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.content.api.XTMAddContentController.getXTMCustomers
     */
    getXTMCustomers$GET$api_content_customers(options?: O): RestResponse<GetCustomersDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/content/customers`, options: options });
    }

    /**
     * HTTP POST /api/content/entries/verify
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.content.api.XTMAddContentController.verify
     */
    verify(xtmProject: XTMProjectVerifyDTO, options?: O): RestResponse<XTMProjectEntryVerifyDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/content/entries/verify`, data: xtmProject, options: options });
    }

    /**
     * HTTP POST /api/content/projects/active
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.content.api.XTMAddContentController.getActiveXTMProjects
     */
    getActiveXTMProjects(activeProjectSearch: ActiveProjectSearchDTO, options?: O): RestResponse<XTMProjectDTO[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/content/projects/active`, data: activeProjectSearch, options: options });
    }

    /**
     * HTTP GET /api/content/templates/{xtmCustomerId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.content.api.XTMAddContentController.getXTMTemplatesForExistingUser
     */
    getXTMTemplatesForExistingUser$GET$api_content_templates_xtmCustomerId(xtmCustomerId: number, options?: O): RestResponse<GetTemplatesDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/content/templates/${xtmCustomerId}`, options: options });
    }

    /**
     * HTTP GET /api/hubspot/blog-post/pageable
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.blog.api.HubspotBlogPostController.findHubspotBlogPosts
     */
    findHubspotBlogPosts(queryParams?: { page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<HubspotProductDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/hubspot/blog-post/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/hubspot/connect
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.auth.app.api.HubspotConnectController.connect
     */
    connect(connectRequest: HubspotConnectRequest, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/hubspot/connect`, data: connectRequest, options: options });
    }

    /**
     * HTTP POST /api/hubspot/connect/refresh
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.auth.app.api.HubspotConnectController.refresh
     */
    refresh(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/hubspot/connect/refresh`, options: options });
    }

    /**
     * HTTP GET /api/hubspot/connect/status
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.auth.app.api.HubspotConnectController.status
     */
    status(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/hubspot/connect/status`, options: options });
    }

    /**
     * HTTP GET /api/hubspot/email/pageable
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.email.api.HubspotEmailController.findHubspotEmails
     */
    findHubspotEmails(queryParams?: { page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<HubspotProductDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/hubspot/email/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/hubspot/form/pageable
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.form.api.HubspotFormController.findHubspotForms
     */
    findHubspotForms(queryParams?: { page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<HubspotProductDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/hubspot/form/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/hubspot/page/languages
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.page.api.HubspotPageController.getLanguageVariations
     */
    getLanguageVariations(pageIds: string[], options?: O): RestResponse<PageLanguageVariationsDTO[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/hubspot/page/languages`, data: pageIds, options: options });
    }

    /**
     * HTTP GET /api/hubspot/page/pageable
     * Java method: cloud.xtm.hubspot.backend.integration.hubspot.page.api.HubspotPageController.findHubspotPages
     */
    findHubspotPages(queryParams?: { page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<HubspotProductDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/hubspot/page/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/jobs
     * Java method: cloud.xtm.hubspot.backend.jobs.app.api.JobsController.findAllPaged
     */
    findAllPaged(queryParams?: { forceRefresh?: boolean; search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<XTMJobDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/jobs`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/jobs
     * Java method: cloud.xtm.hubspot.backend.jobs.app.api.JobsController.performOperation
     */
    performOperation(request: MassOperationRequest, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/jobs`, data: request, options: options });
    }

    /**
     * HTTP POST /api/jobs/operation-applicable-for
     * Java method: cloud.xtm.hubspot.backend.jobs.app.api.JobsController.findJobsIdsByOperationType
     */
    findJobsIdsByOperationType(request: MassOperationRequest, options?: O): RestResponse<string[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/jobs/operation-applicable-for`, data: request, options: options });
    }

    /**
     * HTTP POST /api/notifications/admin/send-reset-password-email
     * Java method: cloud.xtm.hubspot.backend.notification.app.api.NotificationController.sendResetPasswordEmailForAdmin
     */
    sendResetPasswordEmailForAdmin(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/admin/send-reset-password-email`, data: resetPassword, options: options });
    }

    /**
     * HTTP POST /api/notifications/change-password
     * Java method: cloud.xtm.hubspot.backend.notification.app.api.NotificationController.changePassword
     */
    changePassword(changePassword: ChangePasswordDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/change-password`, data: changePassword, options: options });
    }

    /**
     * HTTP POST /api/notifications/send-reset-password-email
     * Java method: cloud.xtm.hubspot.backend.notification.app.api.NotificationController.sendResetPasswordEmail
     */
    sendResetPasswordEmail(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/send-reset-password-email`, data: resetPassword, options: options });
    }

    /**
     * HTTP POST /api/notifications/verify-reset-password-token
     * Java method: cloud.xtm.hubspot.backend.notification.app.api.NotificationController.verifyResetPasswordToken
     */
    verifyResetPasswordToken(verifyToken: VerifyTokenDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/verify-reset-password-token`, data: verifyToken, options: options });
    }

    /**
     * HTTP POST /api/translations/translate
     * Java method: cloud.xtm.hubspot.backend.translation.app.api.XTMTranslationController.translate
     */
    translate(translateRequest: TranslateRequestDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/translations/translate`, data: translateRequest, options: options });
    }

    /**
     * HTTP POST /api/users
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.createUser
     */
    createUser(createUser: CreateUserDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/users`, data: createUser, options: options });
    }

    /**
     * HTTP GET /api/users/import-configuration
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.importConfiguration
     */
    importConfiguration(options?: O): RestResponse<UserConfigurationDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users/import-configuration`, options: options });
    }

    /**
     * HTTP GET /api/users/pageable
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.getAllUsers
     */
    getAllUsers(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<UserOverviewDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/users/{userId}
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.deleteUser
     */
    deleteUser(userId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/users/${userId}`, options: options });
    }

    /**
     * HTTP PUT /api/users/{userId}
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.editUser
     */
    editUser(userId: string, updateUser: UpdateUserDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/users/${userId}`, data: updateUser, options: options });
    }

    /**
     * HTTP GET /api/users/{userId}
     * Java method: cloud.xtm.hubspot.backend.users.app.api.UserController.getUser
     */
    getUser(userId: string, options?: O): RestResponse<UserDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/users/${userId}`, options: options });
    }

    /**
     * HTTP GET /api/xtm/callbacks/jobs/{clientId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.callback.app.api.XTMCallbackController.getJobFinishedCallback
     */
    getJobFinishedCallback(clientId: string, queryParams: { xtmJobId: number; xtmProjectId: number; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/callbacks/jobs/${clientId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/xtm/callbacks/project/analysis/finished/{clientId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.callback.app.api.XTMCallbackController.analysisFinished
     */
    analysisFinished(clientId: string, queryParams: { xtmProjectId: number; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/xtm/callbacks/project/analysis/finished/${clientId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/xtm/connect
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.auth.app.api.XTMConnectController.connectToXtm
     */
    connectToXtm(xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/xtm/connect`, data: xtmConnectRequest, options: options });
    }

    /**
     * HTTP GET /api/xtm/connect/{userId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.auth.app.api.XTMConnectController.getXTMConnectionParameters
     */
    getXTMConnectionParameters(userId: string, options?: O): RestResponse<XTMConnectionParametersDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/connect/${userId}`, options: options });
    }

    /**
     * HTTP POST /api/xtm/connect/{userId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.auth.app.api.XTMConnectController.reconnectToXtm
     */
    reconnectToXtm(userId: string, xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/xtm/connect/${userId}`, data: xtmConnectRequest, options: options });
    }

    /**
     * HTTP GET /api/xtm/customers
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomers
     */
    getXTMCustomers$GET$api_xtm_customers(queryParams: { xtmAuthId: string; }, options?: O): RestResponse<XTMCustomerDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/customers`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/xtm/customers/{userId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomersForExistingUser
     */
    getXTMCustomersForExistingUser(userId: string, options?: O): RestResponse<XTMCustomerDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/customers/${userId}`, options: options });
    }

    /**
     * HTTP GET /api/xtm/languages/hubspot
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.languages.app.api.LanguageController.getHubspotLanguages
     */
    getHubspotLanguages(options?: O): RestResponse<HubspotLanguageDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/languages/hubspot`, options: options });
    }

    /**
     * HTTP GET /api/xtm/languages/xtm
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.languages.app.api.LanguageController.getXtmLanguagesCodes
     */
    getXtmLanguagesCodes(options?: O): RestResponse<string[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/languages/xtm`, options: options });
    }

    /**
     * HTTP GET /api/xtm/templates
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.templates.app.api.XTMTemplatesController.getXTMTemplates
     */
    getXTMTemplates(queryParams: { xtmAuthId: string; xtmCustomerId: number; }, options?: O): RestResponse<XTMTemplateDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/templates`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/xtm/templates/{userId}
     * Java method: cloud.xtm.hubspot.backend.integration.xtm.templates.app.api.XTMTemplatesController.getXTMTemplatesForExistingUser
     */
    getXTMTemplatesForExistingUser$GET$api_xtm_templates_userId(userId: string, queryParams: { xtmCustomerId: number; }, options?: O): RestResponse<XTMTemplateDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/templates/${userId}`, queryParams: queryParams, options: options });
    }
}

export type RestResponse<R> = Promise<Axios.GenericAxiosResponse<R>>;

export type ProductType = "PAGE" | "BLOG_POST" | "EMAIL" | "FORM";

export type JobOperation = "CANCEL" | "DELETE" | "IMPORT";

export type ActivityEnum = "ACTIVE" | "ARCHIVED" | "AUTO_ARCHIVED" | "DELETED" | "INACTIVE";

export type XTMJobStatus = "NEW" | "SENT" | "IN_PROGRESS" | "FINISHED" | "CANCELLED";

export type Direction = "ASC" | "DESC";

export type NullHandling = "NATIVE" | "NULLS_FIRST" | "NULLS_LAST";

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = "";
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}


// Added by 'AxiosClientExtension' extension

import axios from "axios";
import * as Axios from "axios";

declare module "axios" {
    export interface GenericAxiosResponse<R> extends Axios.AxiosResponse {
        data: R;
    }
}

class AxiosHttpClient implements HttpClient<Axios.AxiosRequestConfig> {

    constructor(private axios: Axios.AxiosInstance) {
    }

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: Axios.AxiosRequestConfig; }): RestResponse<R> {
        function assign(target: any, source?: any) {
            if (source != undefined) {
                for (const key in source) {
                    if (source.hasOwnProperty(key)) {
                        target[key] = source[key];
                    }
                }
            }
            return target;
        }

        const config: Axios.AxiosRequestConfig = {};
        config.method = requestConfig.method as typeof config.method;  // `string` in axios 0.16.0, `Method` in axios 0.19.0
        config.url = requestConfig.url;
        config.params = requestConfig.queryParams;
        config.data = requestConfig.data;
        assign(config, requestConfig.options);
        const copyFn = requestConfig.copyFn;

        const axiosResponse = this.axios.request(config);
        return axiosResponse.then(axiosResponse => {
            if (copyFn && axiosResponse.data) {
                (axiosResponse as any).originalData = axiosResponse.data;
                axiosResponse.data = copyFn(axiosResponse.data);
            }
            return axiosResponse;
        });
    }
}

export class AxiosRestApplicationClient extends RestApplicationClient<Axios.AxiosRequestConfig> {

    constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
