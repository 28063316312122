import { css } from '@emotion/core';

export const customTableStyle = {
  root: css`
    margin: 12px;
    .sortLabel {
      svg {
        color: #10a4e4;
      }
    }
  `,
  button: css`
    padding: 4px !important;
  `,
  container: css`
    max-height: 500px;
  `,
  iconContainer: css`
    display: flex;
    .MuiSvgIcon-root:hover {
      color: #10a4e4;
    }
  `,
  spinnerCell: css`
    display: flex !important;
    justify-content: center;
    width: 100%;
  `,
  spinner: css`
    margin: 24px;
  `,
};
