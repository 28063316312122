/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, CircularProgress } from '@material-ui/core';
import CustomLink from 'components/CustomLink/CustomLink';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomField from 'components/Form/Input/CustomField';
import { customInputStyles, ErrorDiv } from 'components/Form/Input/CustomInput.style';
import FormTitle from 'components/Form/Title/FormTitle';
import { createForm, FormApi, Unsubscribe } from 'final-form';
import { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { getFetchSpinnerSelector } from 'store/spinner/spinner.selectors';
import { clearUserErrors, loginUser } from 'store/user/user.actions';
import { getUserErrorsSelector } from 'store/user/user.selectors';
import { composeValidators, email, fetchValidatorObject, required } from 'utils/customValidators';
import { AuthenticationRequest } from 'utils/restApplicationClientTypeOverrides';
import { loginContainerStyles } from './LoginContainer.style';

interface IDispatchProps {
  login: (payload: AuthenticationRequest) => AppDispatch;
  clearUserErrors: () => AppDispatch;
}

interface IStateProps {
  errors: { [key: string]: string };
  fetchSpinner: boolean;
}

interface IFormValues {
  email: string;
  password: string;
}

interface IState {
  submitValues: {
    email: string;
    password: string;
  };
}

type PropType = IDispatchProps & WithTranslation & IStateProps;

export class LoginContainer extends Component<PropType, IState> {
  form: FormApi<IFormValues, Partial<Record<string, unknown>>>;
  unsubscribe: Unsubscribe;
  constructor(props: PropType) {
    super(props);
    this.state = {
      submitValues: {
        email: '',
        password: '',
      },
    };
    this.form = createForm({
      onSubmit: this.onSubmit,
    });
    this.unsubscribe = this.form.subscribe(
      () => {
        if (this.props.errors['login']) {
          this.props.clearUserErrors();
        }
      },
      { values: true },
    );
  }

  componentWillUnmount(): void {
    this.unsubscribe();
  }

  onSubmit = ({ email, password }: IFormValues): void => {
    this.setState({ submitValues: { email, password } });
    this.props.login({ email, password });
  };

  render(): JSX.Element {
    const { t, errors, fetchSpinner } = this.props;
    const { submitValues } = this.state;
    return (
      <FormDiv width={500}>
        <FormTitle text="auth.loginPanel" width={500} />
        <Form
          validate={fetchValidatorObject(errors['login'], submitValues)}
          onSubmit={this.onSubmit}
          form={this.form}
          subscription={{ pristine: true, submitting: true }}
          render={({ handleSubmit, submitting }): JSX.Element => (
            <form onSubmit={handleSubmit} css={loginContainerStyles.form}>
              <Field name="email" validate={composeValidators([required, email])}>
                {({ input, meta }): JSX.Element => (
                  <CustomField
                    label="common.email"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={{ ...input }}
                    testId="emailInput"
                  />
                )}
              </Field>
              <Field name="password" validate={composeValidators([required])}>
                {({ input, meta }): JSX.Element => (
                  <CustomField
                    label="common.password"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={{ ...input }}
                    type="password"
                    testId="passwordInput"
                  />
                )}
              </Field>
              <ErrorDiv>
                <span css={customInputStyles.textErrorNoMargin}>{t(this.props.errors['login'])}</span>
              </ErrorDiv>
              <Button
                variant="contained"
                color="secondary"
                disabled={submitting || fetchSpinner}
                type="submit"
                css={loginContainerStyles.button}
                data-testid="loginButton"
              >
                {fetchSpinner ? <CircularProgress size={24} color="secondary" /> : t('auth.login')}
              </Button>
            </form>
          )}
        />
        <CustomLink
          wide
          testId="remindPasswordLink"
          text="auth.password.forgot"
          linkProps={{ to: 'reset-password/admin' }}
        />
      </FormDiv>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  login: (payload: AuthenticationRequest): AppDispatch => dispatch(loginUser(payload)),
  clearUserErrors: (): AppDispatch => dispatch(clearUserErrors()),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  errors: getUserErrorsSelector(state),
  fetchSpinner: getFetchSpinnerSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginContainer));
