import { Tooltip, TypographyProps } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CustomTooltipTypography } from './CustomTooltip.styled';

interface IProps {
  text: string;
  width: number;
  maxLines: number;
  typographyProps?: TypographyProps;
  textColor?: string;
}

interface IState {
  isOverflown: boolean;
}

type PropType = IProps & WithTranslation;

class Name extends Component<PropType, IState> {
  state = {
    isOverflown: false,
  };

  componentDidMount(): void {
    this.checkIfOverflown();
  }

  componentDidUpdate(): void {
    this.checkIfOverflown();
  }

  checkIfOverflown(): void {
    const { element } = this;

    if (element) {
      const isOverflown = element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;

      if (this.state.isOverflown !== isOverflown) {
        this.setState({ isOverflown });
      }
    }
  }

  element: HTMLSpanElement | null = null;

  render(): JSX.Element {
    const { isOverflown } = this.state;
    const { text, width, t, typographyProps, maxLines, textColor } = this.props;
    const NameTypographyWidth = CustomTooltipTypography(`${width}px`, maxLines, textColor);

    return (
      <Fragment>
        {isOverflown ? (
          <Tooltip title={<Fragment>{t(text)}</Fragment>}>
            <NameTypographyWidth
              {...typographyProps}
              ref={(element): void => {
                this.element = element;
              }}
            >
              {t(text)}
            </NameTypographyWidth>
          </Tooltip>
        ) : (
          <NameTypographyWidth
            {...typographyProps}
            ref={(element): void => {
              this.element = element;
            }}
          >
            {t(text)}
          </NameTypographyWidth>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(Name);
