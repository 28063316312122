/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { customInputStyles, FieldDiv, StyledTextField } from './CustomInput.style';

interface IProps {
  label: string;
  error?: string;
  touched?: boolean;
  inputProps: FieldInputProps<unknown, HTMLElement>;
  type?: string;
  testId?: string;
  disableAutoComplete?: boolean;
}

export class CustomFieldRow extends Component<IProps & WithTranslation> {
  render(): JSX.Element {
    const { t, error, touched, type, testId, inputProps, disableAutoComplete } = this.props;

    return (
      <div css={customInputStyles.root}>
        <FieldDiv>
          <span css={customInputStyles.label}>{t(this.props.label)}</span>

          <StyledTextField
            css={error && touched ? customInputStyles.outlineError : {}}
            variant="outlined"
            margin="dense"
            inputProps={{ ...inputProps, 'data-testid': testId }}
            type={type}
            autoComplete={disableAutoComplete ? 'new-password' : type}
          />
        </FieldDiv>
        {error && touched && (
          <span css={customInputStyles.textError} data-testid={`${testId}Error`}>
            {t(error)}
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(CustomFieldRow);
