import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { IClientState } from './client.interface';

const clientStateSelector = (state: AppState): IClientState => state.client;

export const getClientsPageableSelector = createSelector(clientStateSelector, ({ clientsPageable }) => clientsPageable);

export const getAllClientsSelector = createSelector(clientStateSelector, ({ clients }) => clients);

export const getClientErrorsSelector = createSelector(clientStateSelector, ({ errors }) => errors);

export const getClientSelector = createSelector(clientStateSelector, ({ client }) => client);

export const getXTMLanguageCodesSelector = createSelector(
  clientStateSelector,
  ({ xtmLanguageCodes }) => xtmLanguageCodes,
);
