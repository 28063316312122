import { createAction } from '@reduxjs/toolkit';
import { ICloseAlertPayload, IShowErrorPayload, NotificationActionTypes } from './notify.actions.types';

type ErrorAction = typeof NotificationActionTypes;

export const showError = createAction<IShowErrorPayload, ErrorAction['showError']>(NotificationActionTypes.showError);
export const showSuccessful = createAction<IShowErrorPayload, ErrorAction['showSuccessful']>(
  NotificationActionTypes.showSuccessful,
);
export const closeAlert = createAction<ICloseAlertPayload, ErrorAction['closeAlert']>(
  NotificationActionTypes.closeAlert,
);
