/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch } from '@reduxjs/toolkit';
import ButtonContainer from 'components/Form/ButtonContainer/ButtonContainer';
import { EditData } from 'components/Form/CilentData/EditData';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomCheckbox from 'components/Form/Input/CustomCheckbox';
import CustomFieldRow from 'components/Form/Input/CustomFieldRow';
import { CustomInputError } from 'components/Form/Input/CustomInput.style';
import FormTitle from 'components/Form/Title/FormTitle';
import LanguageMappingContainer from 'containers/LanguageMapping/LanguageMappingContainer';
import arrayMutators from 'final-form-arrays';
import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { AppDispatch, AppState } from 'store';
import {
  clearClient,
  clearClientErrors,
  createClient,
  editClient,
  getClientById,
  getXTMLanguageCodes,
} from 'store/client/client.actions';
import { getClientErrorsSelector, getClientSelector, getXTMLanguageCodesSelector } from 'store/client/client.selectors';
import { getDefaultUserLanguages } from 'store/user/user.actions';
import { getDefaultUserLanguagesSelector } from 'store/user/user.selectors';
import { IEditClient } from 'types/api';
import { ILanguageMappingField } from 'types/shared';
import { composeValidators, fetchValidator, required } from 'utils/customValidators';
import {
  ClientDTO,
  CreateOrUpdateClientDTO,
  HubspotLanguageDTO,
  LanguageMappingDTO,
} from 'utils/restApplicationClient';
import { addClientContainerStyles } from './addClientContainer.style';

export interface IClientForm extends Omit<CreateOrUpdateClientDTO, 'languageMappings'> {
  languageMappings: Array<ILanguageMappingField>;
}

interface IStateProps {
  errors: { [key: string]: string | undefined };
  client: ClientDTO | undefined;
  hubspotLanguages: Array<HubspotLanguageDTO>;
  xtmLanguageCodes: Array<string>;
}

interface IDispatchProps {
  createClient: (payload: CreateOrUpdateClientDTO) => AppDispatch;
  editClient: (payload: IEditClient) => AppDispatch;
  getClientById: (payload: string) => AppDispatch;
  clearClient: () => AppDispatch;
  clearClientErrors: () => AppDispatch;
  getHubspotLanguages: () => AppDispatch;
  getXTMLanguageCodes: () => AppDispatch;
}

// eslint-disable-next-line max-lines-per-function
export const AddClientForm = (props: IStateProps & IDispatchProps): JSX.Element => {
  const {
    params: { id: clientId },
  } = useRouteMatch<{ id: string }>();

  const { t } = useTranslation();
  const { errors, createClient, editClient } = props;
  const [mappingInitialValue, setMappingInitialValue] = useState<Array<ILanguageMappingField> | undefined>(undefined);
  const [submitValues, setSubmitValues] = useState<{
    clientName?: string;
    status?: boolean;
  }>({
    clientName: undefined,
    status: undefined,
  });

  const { client, hubspotLanguages, xtmLanguageCodes } = props;

  const mapLanguages = (
    contentfulXtmLanguagesMap: Array<ILanguageMappingField>,
  ): Array<LanguageMappingDTO> | undefined => {
    return contentfulXtmLanguagesMap?.map(
      ({ hubspotLanguage: { value: hubspotCode }, xtmLanguage: { value: xtmCode } }) => ({ xtmCode, hubspotCode }),
    );
  };

  const onSubmit = (values: IClientForm): void => {
    const languageMappings = mapLanguages(values.languageMappings) || [];

    props.clearClientErrors();
    setSubmitValues(values);

    if (clientId) {
      editClient({ clientId, updateClient: { ...values, languageMappings } });
    } else {
      createClient({ ...values, languageMappings });
    }
  };

  useEffect(() => {
    const { getHubspotLanguages, getXTMLanguageCodes } = props;

    getHubspotLanguages();
    getXTMLanguageCodes();

    if (clientId) {
      props.getClientById(clientId);
    }
    return (): void => {
      props.clearClient();
      props.clearClientErrors();
    };
  }, []);

  useEffect(() => {
    setMappingInitialValue(
      client?.languageMappings?.map(({ hubspotCode, xtmCode }) => ({
        hubspotLanguage: { label: hubspotCode, value: hubspotCode },
        xtmLanguage: { label: xtmCode, value: xtmCode },
      })),
    );
  }, [client]);

  return (
    <FormDiv>
      <FormTitle text={clientId ? 'client.edit' : 'client.add'} />
      <Form
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          pristine: true,
          submitFailed: true,
          errors: true,
        }}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, submitting, pristine, errors: stateErrors, submitFailed, form }): JSX.Element => (
          <form onSubmit={handleSubmit} css={addClientContainerStyles.form}>
            <Field
              name="clientName"
              initialValue={client?.clientName}
              validate={composeValidators([required, fetchValidator(errors['clientName'], submitValues.clientName)])}
              key={errors['clientName'] ? `clientNameError${submitValues.clientName}` : 'clientName'}
            >
              {({ input, meta }): JSX.Element => (
                <CustomFieldRow
                  label={t('client.clientName')}
                  error={meta.error}
                  touched={errors['clientName'] ? true : meta.touched}
                  inputProps={input}
                  testId="clientNameInput"
                />
              )}
            </Field>
            <Field name="status" type="checkbox" initialValue={clientId ? client && client.status : true}>
              {({ input }): JSX.Element => (
                <CustomCheckbox label="common.availability" inputProps={input} testId="checkboxInput" />
              )}
            </Field>
            <LanguageMappingContainer
              form={form}
              hubspotLanguages={hubspotLanguages.map(({ code }) => ({ label: code, value: code }))}
              xtmLanguages={xtmLanguageCodes.map((value) => ({ label: value, value }))}
              initialValue={mappingInitialValue}
            />
            {stateErrors && submitFailed && <CustomInputError>{t(stateErrors.error || '')}</CustomInputError>}
            {client && <EditData createdAt={client.createdAt} modifiedAt={client.modifiedAt} />}
            <ButtonContainer backTo="/clients" submitting={submitting || pristine} />
          </form>
        )}
      />
    </FormDiv>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  createClient: (payload: CreateOrUpdateClientDTO): AppDispatch => dispatch(createClient(payload)),
  editClient: (payload: IEditClient): AppDispatch => dispatch(editClient(payload)),
  getClientById: (payload: string): AppDispatch => dispatch(getClientById(payload)),
  clearClient: (): AppDispatch => dispatch(clearClient()),
  clearClientErrors: (): AppDispatch => dispatch(clearClientErrors()),
  getHubspotLanguages: (): AppDispatch => dispatch(getDefaultUserLanguages()),
  getXTMLanguageCodes: (): AppDispatch => dispatch(getXTMLanguageCodes()),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  errors: getClientErrorsSelector(state),
  client: getClientSelector(state),
  hubspotLanguages: getDefaultUserLanguagesSelector(state),
  xtmLanguageCodes: getXTMLanguageCodesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClientForm);
