import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        padding: '2px',
      },
    },
    MuiTablePagination: {
      toolbar: {
        background: '#fff',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 400,
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: '#fff',
      },
      indicator: {
        backgroundColor: '#0096eb',
        height: '3px',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
        background: '#fff',
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: '#fff',
        '&.MuiTableRow-hover:hover': {
          backgroundColor: '#ccedfa',
        },
      },
    },
    MuiTableContainer: {
      root: {
        width: 'auto',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: '16px',
        color: '#536171 !important',
      },
    },
    MuiAppBar: {
      root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '16px',
        boxShadow: 'none',
        justifyContent: 'space-between',
      },
    },
    MuiTextField: {
      root: {
        background: '#fff',
      },
    },
    MuiSelect: {
      root: {
        background: '#fff',
      },
    },
  },
  palette: {
    primary: {
      main: '#2e3645',
      contrastText: '#FFFFFF',
      light: '5EB555',
    },
    secondary: {
      main: '#0096eb',
    },
  },
});
